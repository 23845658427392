.plans {
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 40px 0px;
    border: 1px solid #eb5b24;
    border-radius: 20px;
    position: relative;
    margin-top: 30px;
    margin-left: 0;
}
.borderNeck {
    border: 2px solid #aabbd0;
    background-color: #aabbd0;
    border-radius: 7px;
}
.shop-product-border {
    border: 1px solid #1b572c;
    border-radius: 20px;
    padding: 50px 0px !important;
    margin-top: 30px;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
}

.shop-product-label {
    position: absolute;
    top: 0;
    background-color: #1b572c;
    color: white;
    border-radius: 7px;
    top: -12px;
    padding: 0 10px;
}

.plans-label {
    position: absolute;
    top: -12px;
    border-radius: 7px;
    background-color: #eb5b24;
    padding: 0 10px;
    border: none;
    color: white;
    margin-bottom: 0 !important;
}

.box {
    width: 270px;
    height: 470px;
    border: 1px solid #707070;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    position: relative;
    text-align: center;
    margin: 0 10px;
    cursor: pointer;
    background-color: white;
}

.cardText {
    padding-top: 20px;
}

.box-plan-image {
    width: 100%;
    height: 100px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    border-radius: 7px;
    background-position: left;
}

.sub-type p {
    font-size: 21px;
    color: #1b6047;
    font-weight: 500;
    line-height: 25px;
}
.empty {
    display: none;
}
.sub-type > p > span {
    color: #686868;
    font-size: 19px;
}

.price-plans {
    color: #686868;
    font-size: 20px;
    font-weight: 500;
    margin: 0;
}

.extra-plans {
    width: 100%;
    height: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 15px 0;
    font-size: 14px;
    padding: 20px 0px;
    color: #66aa47;
}
.extra-pl2 {
    height: 90px !important;
}
.extra-pl2 p {
    font-size: 13px !important;
}
.extra-plans p {
    color: #48874c;
    font-size: 15px;
    line-height: 18px;
    padding-bottom: 10px;
    margin: 0;
}

.plans-sub-btn {
    width: 100%;
    height: 40px;
    /* margin: 5px 0 0 0; */
    outline: none;
    background-color: transparent;
    color: #48874c;
    font-size: 15px;
    border: 1px solid #48874c;
    border-radius: 7px;
    font-weight: 600;
}

.plans-sub-btn p {
    margin: 0;
}

.ribon {
    position: absolute;
    width: 150px;
    top: -10px;
    right: -40px;
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
    z-index: 48 !important;
}

.ribon {
    position: absolute;
    width: 150px;
    top: -10px;
    right: -40px;
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
    z-index: 1;
}

.satisfied {
    width: 1100px;
    padding: 0 20px;
    text-align: center;
}

.plans-title {
    color: #0b3144;
    text-align: center;
    margin: 20px 0;
    padding: 0 20px;
}

.box-discount {
    width: fit-content;
    margin: 0;
    position: relative;
}
.silverPrice {
    background-color: #aabbd0;
    position: relative;
    text-align: left;
    padding: 5px 10px;
    border-radius: 0px 0px 7px 7px;
    display: flex;
    justify-content: flex-end;
}
.silverPrice img {
    position: absolute;
    left: 0px;
    height: 100px;
    bottom: -1px;
}
.boxBorder {
    border: 1px solid #aabbd0;
    background-color: #66aa47;
    border-radius: 7px;
}
.imageBorder {
    border-bottom: 1px solid #707070;
}
.silverPrice p {
    color: white;
    font-size: 10px;
    margin: 0px;
    width: 50%;
}
.plans-discount-label {
    height: 42px;
    background-color: #eb5b24;
    border-radius: 0 0 10px 10px;
    margin-top: -10px;
    z-index: 1 !important;
    width: 270px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    justify-content: flex-start;
}

.plans-discount-label p {
    color: white;
    margin-top: 15px;
    font-size: 14px;
}

.gifting-plans-link {
    margin: 0px auto;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    padding: 0 20px;
}

.gifting-plans-link a {
    color: #66aa47;
    text-decoration: none;
    font-weight: bold;
}

#shop-product-label-paragraph,
#plans-label-paragraph,
#plans-label-id,
#plans-label-gifting-form,
#shop-product-label-form,
#shop-product-label-gifting,
#plans-label-giftingplans {
    margin: 0 !important;
    width: fit-content;
}
.box-notice {
    font-size: 10px;
    padding-top: 10px;
}
.box-notice p {
    margin: 0;
    font-size: 10px;
    opacity: 0.9;
}

.show-on-mobile {
    display: none;
}

.hide-on-mobile {
    display: flex;
    margin-top: 15px;
}

.monthPrice {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.box-plan-image {
    position: relative;
}

.boxPng {
    height: 100%;
    border-bottom-left-radius: 10px;
    position: absolute;
    left: 0;
    bottom: 0;
}
.lazyload-placeholder {
    height: fit-content !important;
}
.fullWidth {
    min-height: 140px;
}
@media screen and (max-width: 1440px) {
    .box {
        width: 275px;
        /* height: 420px; */
        margin: 0 5px;
    }

    .satisfied {
        max-width: 100%;
    }

    .plans-discount-label {
        width: 275px;
    }

    .plans-discount-label p {
        color: white;
    }
}

@media screen and (max-width: 1250px) {
    .products {
        width: 100%;
        flex-direction: row;
        align-items: center;
    }

    .plans {
        width: 65%;
    }

    .shop-product-border {
        margin: 50px auto;
        padding: 40px 15px !important;
    }

    .plans {
        width: 100%;
        margin: 0 auto;
    }

    .box {
        width: 239px;
        height: 500px;
        margin: 0 5px;
    }

    .plans-discount-label {
        width: 239px;
    }

    .plans-discount-label p {
        color: white;
    }
}

@media screen and (max-width: 1024px) {
    .box {
        width: 220px;
        height: 330px;
    }

    .plans-discount-label {
        width: 220px;
    }

    .sub-type p {
        font-size: 20px;
        line-height: 17px;
    }

    .sub-type > p > span {
        font-size: 17px;
    }

    .price {
        font-size: 18px;
    }

    .extra p {
        font-size: 13px;
        line-height: 16px;
    }

    .plans-sub-btn {
        height: 40px;
    }
}

@media screen and (max-width: 998px) {
    .cardText {
        width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 10px;
        height: auto;
        justify-content: center;

        /* justify-content: space-between; */
    }
    /* .cardText p,
    .sub-type > p {
        text-align: center !important;
    } */
    .box-plan-image {
        width: 60%;
        height: 130px;
        margin: 0;
        background-position: center;
    }

    .shop-product-border {
        margin: 10px auto;
    }

    .plans {
        order: -1;
    }

    .plans-sub-btn {
        height: 40px;
        width: 130px !important;
        margin: 0 10px;
    }

    .plans-sub-btn p {
        margin: 0;
        font-size: 12px;
    }

    .price-plans p {
        margin: 0;
        font-size: 13px;
    }

    .extra-plans p {
        color: #66aa47;
        font-size: 13px;
        line-height: 18px;
        margin: 0;

        text-align: left;
        width: 100%;
    }

    .plans {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 40px;
        grid-gap: 40px;
        gap: 40px;
        width: 98%;
        margin: 0 auto;
        padding: 30px 0px 20px 0px;
        overflow: hidden;
        border: none;
        height: auto;
    }

    .box-discount {
        width: -moz-fit-content;
        width: fit-content;
        margin: 0;
        position: relative;
        margin: 0 auto;
    }

    /* .plans {
    width: 95%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    gap: 20px;
  } */

    .plans-label {
        position: relative;
    }

    .box {
        margin: 0 auto !important;
        width: 100%;
        height: 100%;
    }

    .plans-discount-label {
        width: 96%;
    }

    .sub-type > p {
        font-size: 16px;
        margin: 0;
        color: #2b2b2b;
        text-align: left;
        font-weight: 600;
        line-height: 24px;
    }

    .price-plans {
        font-size: 16px;
        font-weight: 400;
    }

    .plans-sub-btn {
        color: #48874c;
        border-color: #48874c;
    }

    .sub-type {
        margin: 0;
    }

    .price {
        width: 100%;
        margin: 10px 0;
    }

    .extra-plans {
        margin: 15px 0;
        height: fit-content !important;
    }

    .plans-sub-btn {
        margin: 0;
    }

    #trustBox-slider {
        width: 100%;
        padding-top: 0px;
        margin-bottom: 25px;
        /* margin: 0; */
    }

    .ribon {
        position: absolute;
        width: 150px;
        top: -10px;
        right: -10px;
        transform: rotate(10deg);
        z-index: 1;
    }

    .extra-plans {
        width: 100%;
        height: min-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 10px 0;
    }

    .extra-plans {
        width: 315px;
        height: min-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 10px 0;
    }

    .ribon {
        position: absolute;
        width: 130px;
        top: -35px;
        right: 0px;
        transform: rotate(-4deg);
        z-index: 1;
    }

    .show-on-mobile {
        display: flex;
        color: #68686880;
        margin-bottom: 10px;
        padding: 10px 0;
    }

    .hide-on-mobile {
        display: none;
    }

    .shop-product-border {
        padding: 10px 0px 0px 0px !important;
        width: 98%;
        border: none;
        overflow: hidden;
    }
    .extraDiv {
        display: flex;
        align-items: center;
        flex-direction: row;
        width: 100%;
    }
    .extra-plans {
        width: -moz-fit-content;
        width: fit-content;
    }

    .shop-product-label {
        top: 0px;
    }

    .box-shop {
        width: 100%;
        height: fit-content;
        flex-direction: row;
    }

    .box-discount {
        margin: 0 auto;
        width: 100%;
    }

    .box {
        flex-direction: column;
        width: 96%;
    }
    .silverPrice p {
        display: none;
    }
    .boxBorder {
        width: 60%;
    }
    .box-plan-image {
        width: 100%;
    }
    .silverPrice {
        padding: 30px;
    }
    .silverPrice img {
        height: 80px;
    }
    .fullWidth {
        width: 60%;
    }
}

@media screen and (max-width: 600px) {
    .shop-product-border {
        width: 98%;
        border: none;
        overflow: hidden;
    }

    .shop-product-label {
        top: 0px;
        display: none;
    }

    .plans-label {
        display: none;
    }
    .sub-type {
        text-align: left !important;
    }
    .extra-plans {
        width: 100%;
        height: min-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* margin: 10px 0; */
        text-align: left;
        padding: 0px;
    }
    .silverPrice p {
        display: none;
    }
    .cardText {
        align-items: flex-start;
    }
    .extra-plans {
        width: fit-content;
        height: fit-content !important;
    }
    .boxBorder {
        width: 60%;
    }
    .box-plan-image {
        width: 100%;
    }
    .silverPrice {
        padding: 30px;
    }
    .silverPrice img {
        height: 80px;
    }
    .fullWidth {
        width: 60%;
    }
    .boxPng {
        height: 60%;
    }
}
