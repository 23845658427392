.draw-offer {
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
}

.draw-offer h4 {
    text-align: center;
    color: rgb(80, 127, 80);
    margin-top: 55px;
}

#thebade {
    cursor: pointer;
}

span.shamrockicn {
    color: green;
    font-size: 20px;
}

#badge2 {
    width: 100px;
    height: 100px;
    display: flex;
    margin-top: -80px;
}

.ReactModal__Content.ReactModal__Content--after-open {
    width: 760px;
    height: 565px;
    margin: 0 auto;
    overflow: visible !important;
    border-radius: 20px !important;
    /* border: none !important; */
}

.draw-offer p {
    font-size: 14px;
}

.react-reveal.thebade {
    position: absolute;
    top: 0;
    right: 15%;
    cursor: pointer;
}

.react-reveal.thebade img {
    width: 100px;
}

div#closemodal-badge {
    position: absolute;
    right: 20px;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
}
.modal-fade-in {
    animation-name: modalFadeIn;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
}

@keyframes modalFadeIn {
    from {
        opacity: 0;
        transform: translateY(100%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
@media (max-width: 999px) {
    .draw-offer h4 {
        margin-top: 5px;
    }

    .react-reveal.thebade {
        position: absolute;
        top: 40px;
        right: 5%;
    }

    .draw-offer {
        width: 100%;
        margin: auto;
        margin-bottom: 20px;
        order: 5;
        padding: 20px 0px;
    }

    .ReactModal__Content.ReactModal__Content--after-open {
        width: auto;
        height: fit-content;
        position: absolute;
        top: 40px;
        inset: 40px 0px !important;
        right: 5%;
        margin: 0 auto;
        overflow: visible !important;
        border-radius: 20px !important;
        height: 100%;
        /* border: none !important; */
    }

    .draw-offer p {
        font-size: 12px;
        margin-bottom: 10px !important;
    }
}

@font-face {
    font-family: 'Kells';
    src: url('../Styles/Fonts/kellsRound.woff') format('woff');
}

.form {
    background-color: #fffcf8;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100vh;
    width: 100%;
    position: relative;
}

button {
    cursor: pointer;
}

.display-none {
    display: none;
}

.display-flex {
    display: flex;
}

.display-g {
    display: g;
}

#progress-bar-gifting {
    margin-top: 100px;
}

.submitBtn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.logoForm {
    margin-top: 0px;
    width: 110px;
    height: 90px;
}

#test {
    height: 0 !important;
}

.logoFormContainer {
    background-color: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 20% !important;
    position: absolute;

    left: 0;
    top: 0;
    height: 50px;
}

.form-footer {
    width: 100%;
    height: 150px;
    background-color: white;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.form-footer p {
    font-size: 14px;
    margin: 10px 0 0 0;
}

.form-footer a {
    text-decoration: none;
    color: #66aa47;
}

.form-footer p:first-child {
    margin-top: 25px;
}

.form-footer span {
    color: #66aa47;
}

.headerForm {
    color: #0b3144;
    font-size: 15px;
    margin: 0;
    padding: 0;
    width: 100%;
    text-align: center;
}

.paragraphForm {
    font-size: 12px;
    color: black;
    margin-bottom: 0px;
    margin-top: 10px;
    text-align: center;
}

.usernameInput {
    width: 300px;
    height: 50px;
    border: 1px solid #707070;
    border-radius: 10px;
    background-color: #fffcf8;
    padding: 0 20px;
    outline: none;
}

.userInfo {
    width: 500px;
    border: 1px solid #707070;
    border-radius: 10px;
    height: 63px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    background-color: white;
}

.userInfo input {
    width: 50%;
    border: none;
    outline: none;
    height: 80%;
    background-color: transparent;
    padding-left: 15px;
    font-size: 16px;
}

.error {
    border: 1px solid red !important;
}

.userInfo span {
    width: 1.5px;
    height: 110%;
    background-color: black;
    transform: rotate(7deg);
}

input:focus {
    outline: none;
}

.formBtns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
    position: relative;
    margin-bottom: 60px;
}

.modalbtn {
    margin: auto;
}

.formBtns button,
.modalbtn {
    width: 150px;
    height: 45px;
    background-color: #66aa47;
    border: none;
    border-radius: 7px !important;
    font-size: 12px;
    color: white;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 0;
}

.rightArrow {
    margin: 0 0 0 10px;
}

.skipBtn {
    border: none;
    cursor: pointer;
    background-color: transparent;
    font-size: 12px;
    color: black;
    margin: 10px 0 0 0;
}

.products {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 90%;
}

.connections {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.card {
    width: 200px;
    height: 220px;
    border: 1px solid #707070;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background-color: white;
    margin: 0 5px;
}

.card:hover {
    transform: scale(1.02);
    transition: ease-in-out 0.3s;
    cursor: pointer;
}

.cardImg {
    width: 65%;
    border-radius: 7px;
}

.product-text {
    text-align: center;
}

#none .cardImg {
    display: none;
}

#none {
    border: 1px dashed #eb5b24;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
}

.extra-info {
    font-size: 12px;
    color: #66aa47;
    padding-top: 15px;
    font-weight: bold;
}

#none p {
    color: #eb5b24;
}

.cardImg img {
    width: 100%;
    margin-top: 15px;
    height: 90%;
}

#none .cardImgConnection {
    display: none;
}

.cardImgConnection img {
    width: 110px;
}

.months {
    color: #1b6047;
    font-size: 15px;
    font-weight: 600;
    margin: 10px 0 0 0;
}

.connection-desc {
    color: #1b6047;
    font-size: 12px;
    font-weight: 185;
    width: 90%;
    text-align: center;
    font-weight: 600;
}

.subscription {
    font-size: 15px;
    font-weight: 600;
    color: #686868;
    margin-top: -5px;
}

.price {
    color: #686868;
    font-size: 14px;
    margin-top: 7px;
}

.citiesDiv {
    width: 300px;
    height: 63px;
    border: 1px solid #707070;
    border-radius: 10px;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    z-index: 444444 !important;
    background-color: white;
    position: relative;
}

.citiesDiv p {
    font-size: 12px;
    color: black;
    margin: 5px 0 0 15px;
}

.citiesDropwdown {
    width: 290px;
    height: 20px;
    border: none !important;
}

.Dropdown-control {
    border: none !important;
    background-color: transparent !important;
    padding: 0 !important;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 3px !important;
}

.Dropdown-placeholder {
    margin: 0 0 0 10px;
    width: 85%;
    background-color: transparent !important;
}

.Dropdown-menu {
    border: 1px solid #707070;
    border-radius: 10px;
    margin-top: 27px !important;
    max-height: fit-content !important;
}

.Dropdown-option.is-selected {
    background-color: #66aa47 !important;
    color: white !important;
}

.Dropdown-option:hover {
    background-color: #66aa47 !important;
    color: white !important;
}

.Dropdown-option {
    color: black !important;
}

.Dropdown-option:hover {
    background-color: #66aa47;
}

.Dropdown-option.is-selected {
    background-color: #66aa47;
    color: #333;
}

.arrow-closed,
.arrow-open {
    margin-bottom: 30px;
}

.emailAddress {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    height: 100px;
}

.agreement {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.agreement input {
    margin: 0;
    border: 1px solid #66aa47;
}

.agreement p {
    font-size: 12px;
    margin: 0;
    padding-left: 10px;
    text-decoration: none;
}

.agreement span {
    text-decoration: none;
    color: #66aa47;
}

.agreement a {
    text-decoration: none;
    color: #66aa47;
}

.cardConnection {
    width: 160px;
    height: 160px;
    border: 1px solid #707070;
    border-radius: 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
}

.commingNext {
    border: 1px solid #1b6047;
    border-radius: 10px;
    width: 700px;
    height: 160px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

#border-paragraph {
    color: black;
    font-size: 14px;
    width: 150px;
    background-color: #fffcf8;
    text-align: center;
    position: absolute;
    top: 0;
    margin-top: -12px;
}

#nextEvent {
    margin: 0;
    font-size: 30px;
    font-family: 'Kells';
    color: #1b6047;
    margin-top: 0px;
    text-align: center;
}

#nextEvent-description {
    margin: 0;
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
    padding: 0 15px;
}

.skipBtnDiv {
    width: 836px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    background-color: #fffcf8;
    position: relative;
}

.tick {
    background-color: #66aa47;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    position: absolute;
    right: 0;
    margin-top: -10px;
    margin-right: -10px;
}

.product-image {
    width: 50%;
    height: 50%;
}

.tags {
    position: absolute;
    right: 0;
    margin: -5px -10px 0 0;
    z-index: 100;
}

.progressbar {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.progress-line {
    width: 70px;
    height: 1px;
    background-color: #66aa47;
}

.userInfo-input {
    transition: all 0.2s ease-in-out;
    width: 100%;
}

.userInfo-input input {
    width: 100%;
    transition: all 0.2s ease-in-out;
    font-size: 16px;
}

.userInfo-input p {
    font-size: 18px;
    transition: all 0.2s ease-in-out;
    margin: 0px 0 0 15px;
    padding-top: 25px;
}

.userInfo-input.active p {
    font-size: 12px;
    transition: all 0.2s ease-in-out;
    margin: 0px 0 0 15px;
    padding-top: 0;
}

.email-input.active p {
    font-size: 12px;
    transition: all 0.2s ease-in-out;
    margin: 0px 0 0 15px;
    padding-top: 5px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
}

.email-input {
    width: 300px;
    border: 1px solid #707070;
    border-radius: 10px;
    height: 63px;
    transition: all 0.2s ease-in-out;
    background-color: white;
    overflow: hidden;
}

.email-input p {
    font-size: 18px;
    transition: all 0.2s ease-in-out;
    margin: 0px 0 0 15px;
    padding-top: 17px;
}

.satisfied {
    color: #0b3144;
    width: 70%;
    text-align: center;
    margin-top: 30px;
    font-size: 18px;
}

.email-input input {
    width: 100%;
    transition: all 0.2s ease-in-out;
    font-size: 16px;
    border: none;
    padding-left: 15px;
    height: auto;
}

.submit-button-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
}

.goback p {
    font-size: 12px;
}

.body {
    width: 100% !important;
}

form {
    margin: 0;
    width: 100%;
    /* z-index: 9999999 !important; */
}

.submitBtn {
    width: 150px;
    height: 45px;
    background-color: #66aa47;
    border-radius: 7px !important;
    border: none;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.submitBtn p {
    margin: 0;
}

.progressbar button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.subblyBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.subblyBtn p {
    margin: 0;
    text-decoration: none;
}

.formBtns a {
    text-decoration: none;
}

.cardConnection:hover {
    transform: scale(1.02);
    transition: ease-in-out 0.3s;
    cursor: pointer;
}

.mobile-header-form {
    width: 100%;
}

.body {
    height: auto;
}

.form-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: max-content;
    height: fit-content;
}

.errorDiv p {
    padding-left: 10px;
}

.goback-div {
    position: absolute;
    left: 0;
    width: 100px;
    display: flex;
    align-items: center;
    bottom: 0;
}

#trustBox-slider {
    width: 100%;
    padding-top: 0px;
    margin-top: 150px;
}

.goback-div p {
    display: block;
    font-size: 12px;
    cursor: pointer;
    left: 15px;
    margin: 0;
}

.goBack {
    display: none;
}

.goback-div {
    display: flex !important;
}

.goBack {
    display: none !important;
}

.unlockedOffer {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #b3d3a0;
    padding: 12px;
}

.unlockedOffer p {
    width: 100%;
    color: #37741c;
    font-weight: 600;
    margin: 0;
}

#goback-svg {
    display: none !important;
}

.input-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 300px;
    border: 1px solid #707070;
    border-radius: 10px;
    height: 63px;
}

.input-container:focus-within label {
    transform: translate(0, 12px) scale(0.8);
    color: #707070;
}

.input-container .filled {
    transform: translate(0, 12px) scale(0.8);
}

.input-container label {
    position: absolute;
    pointer-events: none;
    transform: translate(0, 23px) scale(1);
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    color: #6f81a5;
    font-size: 16px;
    line-height: 1;
    left: 16px;
}

.input-container input {
    height: 64px;
    border-radius: 4px;
    border: none;
    padding: 24px 16px 4px 20px;
    font-size: 16px;
    line-height: 1;
    outline: none;
    box-shadow: none;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    width: 100%;
    border-radius: 10px;
}

.input-container2 {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 63px;
}

.input-container2:focus-within label {
    transform: translate(0, 12px) scale(0.8);
}

.input-container2 .filled {
    transform: translate(0, 12px) scale(0.8);
}

.input-container2 label {
    position: absolute;
    pointer-events: none;
    transform: translate(0, 23px) scale(1);
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    color: #6f81a5;
    font-size: 16px;
    line-height: 1;
    left: 16px;
}

.input-container2 input {
    height: 64px;
    border: none;
    padding: 24px 16px 4px 16px;
    font-size: 16px;
    line-height: 1;
    outline: none;
    box-shadow: none;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    width: 100%;
    border-radius: 10px;
}

@media screen and (max-width: 1100px) {
    .box {
        width: 190px;
        height: 265px;
        margin: 0 5px;
    }
}

@media screen and (max-width: 998px) {
    .logoFormContainer {
        position: relative;
    }

    .products {
        width: 100%;
    }

    #progress-bar-gifting {
        margin: 50px 0px;
    }

    #nextEvent {
        margin: 0;
        font-size: 20px;
        font-family: 'Kells';
        color: #1b6047;
        margin-top: 0px;
        text-align: center;
        margin-top: 10px;
    }

    .goback-div {
        display: none !important;
    }

    #goback-svg {
        display: block !important;
    }

    .goBack {
        display: flex !important;
        width: 45px;
        height: 45px;
        background-color: #fffcf8;
        color: #66aa47;
        align-items: center;
        justify-content: center;
        box-shadow: -8px 0px 17px 1px rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: -8px 0px 17px 1px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: -8px 0px 17px 1px rgba(0, 0, 0, 0.2);
    }

    .logoFormContainer {
        height: 65px;
    }

    .logoForm {
        margin-top: 0px;
        width: 130px;
    }

    #launcher {
        bottom: 170px !important;
        z-index: 111111111111111 !important;
    }

    #trustBox-slider {
        width: 100%;
        padding-top: 50px;
        margin-bottom: 50px;
    }

    .formBtns button {
        width: 100%;
        border-radius: 0 !important;
    }

    .submit-button-div {
        bottom: 0px;
        background-color: #fffcf8;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 95px;
        padding: 25px 10px;
    }

    .submitBtn {
        width: 95%;
        margin: 0;
        border-radius: 0 !important;
    }

    .form-body {
        width: 100%;
    }

    .progressbar img {
        width: 35px;
    }

    .progressbar {
        width: 300px;
    }

    .headerForm {
        width: 100%;
    }

    .headerForm h1 {
        font-size: 20px;
    }

    .mobile-header-form {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .connections {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        height: unset;
        grid-gap: 3px;
        grid-column-gap: 76px;
        grid-column-gap: 0;
        grid-column-gap: 0;
        column-gap: 0;
        grid-row-gap: 0;
        grid-row-gap: 15px;
        grid-row-gap: 15px;
        row-gap: 15px;
        width: 360px;
    }

    div#none {
        width: 352px;
        margin: 0 0 0 7px !important;
        height: 50px !important;
    }

    .cardConnection {
        margin: 0 auto !important;
    }

    .progressbar {
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .skipBtnDiv {
        padding-bottom: 20px;
        width: 100%;
        text-align: center;
        justify-content: space-around !important;
        position: relative;
        display: none;
    }

    .Dropdown-menu {
        max-height: 255px !important;
    }

    .products {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(1, 1fr);
        height: auto;
        grid-gap: 0;
        grid-column-gap: 8px;
        grid-column-gap: 8px;
        grid-column-gap: 8px;
        column-gap: 8px;
        grid-row-gap: 8px;

        grid-row-gap: 8px;
        grid-row-gap: 8px;
        row-gap: 0px;
        width: 95%;
    }

    .card {
        width: 100%;
        height: 90px;
        margin: 0;
        display: flex;
        flex-direction: row;
    }

    .unlockedOffer {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #b3d3a0;
    }

    .unlockedOffer p {
        width: 100%;
        color: #37741c;
        font-size: 14px;
        font-weight: 600;
    }

    .extra-info {
        font-size: 11px;
        width: 140px;
        color: #66aa47;
        padding-top: 0px;
        line-height: 14px;
        padding-bottom: 5px;
    }

    .cardImg {
        width: 25%;
        border-radius: 7px;
        margin-top: 0px;
    }

    .months {
        margin: 0px 0 -7px 0;
    }

    .subscription {
        margin-top: 0;
        margin-bottom: -1px;
    }

    .tags {
        right: -12px;
        margin: 0;
        top: -15px;
    }

    .price {
        font-size: 14px;
        margin-top: 0;
        text-align: center;
        width: 60px;
        line-height: 15px;
    }

    .tick {
        background-color: #66aa47;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        position: absolute;
        top: 0px;
        left: -6px;
        right: unset;
    }

    .formBtns {
        background-color: #fffcf8;
        margin: 0;
        width: 100%;
        padding: 25px 10px;
        align-items: center;
        justify-content: center;

        bottom: 0;
    }

    .goback {
        left: unset;
        bottom: -30px;
    }

    .subblyBtn {
        display: flex;
    }

    .react-reveal.commingNext {
        width: 95%;
        height: fit-content;
        text-align: center;
        vertical-align: middle;
        margin-top: 30px;
        padding: 20px 0;
    }

    .cardConnection {
        width: 170px;
        height: 155px;
    }

    #nextEvent-description {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-top: 10px;
        padding: 0 6px;
    }

    .form-footer {
        margin-top: 95px;
    }
    #none p {
        margin: 0px !important;
    }
}

@media screen and (max-width: 600px) {
    .userInfo {
        width: 95%;
        margin: 0 auto;
    }

    .errorDiv {
        width: 95% !important;
    }

    .errorDiv p {
        padding-left: 5px;
    }

    .satisfied {
        color: #0b3144;
        width: 95%;
        text-align: center;
        margin-top: 30px;
        font-size: 16px;
    }
}
