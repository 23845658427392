.container .content h2 {
    margin: 0 0 10px 0;
    font-size: 22px !important;
    font-weight: 700 !important;
    color: #006e51;
    padding: 20px;
    padding-bottom: 0px;
    font-family: 'Kells';
}

.about-menu {
    display: flex;
    width: 50%;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    font-size: 18px;
    letter-spacing: 3px;
    color: #004413;
    margin-top: 50px;
    padding-bottom: 30px;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
    background: #ffffff;
}

.banner > img:nth-child(1) {
    width: 100%;
}

.content {
    background-color: #ebf1e1;
}

.banner img {
    background-size: cover;
    width: 100%;
}

.timeline {
    position: relative;
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    padding: 15px 0;
}

.timeline::after {
    content: '';
    position: absolute;
    width: 5px;

    top: 0;
    bottom: 1%;
    left: 50%;
    margin-left: -1px;
    background: linear-gradient(
        180deg,
        rgba(27, 87, 44, 1) 0%,
        rgba(27, 87, 44, 1) 97%,
        rgba(255, 255, 255, 1) 100%
    ) !important;
}

.about-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.about-button-2,
.about-button-1 {
    background-color: transparent;
    padding: 0;
    margin: 0 10px;
    font-family: 'Kells';
    border: none;
    opacity: 0.4;
}

.about-button-2 p,
.about-button-1 p {
    color: #1b572c;
    font-family: 'Kells';
    padding: 10px;
    font-weight: 600;
    font-size: 30px;
}

#about-active {
    border: none;
    opacity: 1;
}

.container {
    padding: 15px 30px;
    position: relative;
    background: inherit;
    width: 50%;
}

.our-founder {
    background-color: #fffcf8;
    max-width: 1100px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 250px;
}

.our-founder-image-1 {
    width: 100%;
    height: 448px;
    border-radius: 20px;
}

.our-founder p {
    padding: 20px 0;
    font-weight: 500;
    color: #1b572c;
    font-size: 20px;
}

.second-images-our-founder {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 50px 0;
    height: fit-content;
}

.second-images-our-founder img {
    width: 50%;
    border-radius: 30px;
    height: 350px;
    padding: 2px 10px;
}

.container.left {
    left: 0;
    margin-left: -10%;
}

.business-info {
    margin: 0;
}

.business-info ul {
    padding-left: 0px;
    padding-top: 10px;
}

.business-info li {
    margin: 0;
    list-style: none;
}

.container.right {
    left: 50%;
    margin-left: 0;
}

.pagechanger {
    font-size: 22px;
}

.memory-paragraph {
    font-size: 18px !important;
}

.container::after {
    content: '';
    position: absolute;
    right: -8px;
    background: #1b572c;
    z-index: 1;
    width: 30px;
    height: 30px;
    top: calc(50% - 16px);
    right: -16px;
    border: 5px solid #1b572c;
    border-radius: 30px;
}

.container.right::after {
    left: -14px;
}

.content img {
    width: 376px;
}

.container::before {
    content: '';
    position: absolute;
    height: 2px;
    top: calc(50% - 3px);
    right: 8px;
    background: #1b572c;
    z-index: 1;
    width: 146px;
}

.container.right::before {
    left: 8px;
}

.container .date {
    position: absolute;
    display: inline-block;
    top: calc(50% - 8px);
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: #1b572c;
    text-transform: uppercase;
    letter-spacing: 1px;
    z-index: 1;
}

.container.left .date {
    right: -75px;
}

.container.right .date {
    left: -75px;
}

.container .icon {
    position: absolute;
    display: inline-block;
    width: 40px;
    height: 40px;
    padding: 9px 0;
    top: calc(50% - 20px);

    border: 2px solid #006e51;
    border-radius: 40px;
    text-align: center;
    font-size: 18px;
    color: #006e51;
    z-index: 1;
    display: none;
}

.container.left .icon {
    right: 56px;
}

.container.right .icon {
    left: 56px;
}

.container .content {
    width: 382px;
    border: 3px solid #004413;
    position: relative;
}

.container .content h2 {
    margin: 0 0 10px 0;
    font-size: 18px;
    font-weight: normal;
    color: #006e51;
    padding: 20px;
    padding-bottom: 0px;
}

.container .content p {
    margin: 0;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    padding: 0px 20px 20px 20px;
}

.container::before {
    content: '';
    position: absolute;
    height: 2px;
    top: calc(50% - 3px);
    right: 8px;
    background: #1b572c;
    z-index: 1;
    width: 21.1%;
}

.container {
    padding: 15px 30px;
    position: relative;
    background: inherit;
    width: 60%;
    overflow: hidden !important;
}

.content {
    position: relative;

    overflow: hidden;

    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.content img {
    vertical-align: top;
    max-width: 100%;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 1.7s;
}

.content:hover img {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.container .content {
    width: 382px;
    border: none !important;
    position: relative;
}

.container:hover .content:hover {
    width: 382px;
    border: 3px solid #004413 !important;
    position: relative;
}

.join {
    padding-top: 50px;

    width: 100% !important;
    text-align: center;
    margin-top: 100px;
    background-color: #66aa4713;
    padding-bottom: 50px;
}

.join h2 {
    font-size: 38px;
    font-weight: 900;
    padding: 10px 0px;
    font-weight: bolder;
    color: #0b3144;
}

.join p {
    font-size: 18px;
    padding: 10px 0px;
    color: #0b3144;
}

.join a {
    margin: 0;
}

.join_buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 640px;
    margin-top: 20px;
}

.join_button {
    padding: 10px 20px;
    background-color: #48874c;
    color: white;
    font-size: 18px;
    border: none;
    border-radius: 10px;
}

.gift_button {
    padding: 10px 20px;
    background-color: transparent;
    color: #0b3144;
    font-size: 18px;
    border: 1px solid #2b3143;
    border-radius: 10px;
}

.about-menu > div:nth-child(2) > p:nth-child(1) {
    color: #2b314338;
}

.container {
    overflow: visible !important;
}

@media screen and (max-width: 998px) {
    .second-images-our-founder {
        flex-direction: column;
    }

    .second-images-our-founder img {
        margin: 0;
        width: 100%;
        height: 100%;
        padding: 10px 10px;
    }

    .our-founder-image-1 {
        padding: 0 10px;
    }

    .our-founder-image-1 {
        height: auto;
    }

    .our-founder p {
        padding: 20px 20px;
        font-size: 18px;
    }

    .about-menu {
        padding-bottom: 50px;
    }
}

@media (max-width: 767px) {
    .timeline::after {
        left: 90px;
    }

    .container {
        width: 100%;
        padding-left: 120px;
        padding-right: 30px;
    }

    .container.right {
        left: 0%;
    }

    .container.left::after,
    .container.right::after {
        left: 82px;
    }

    .container.left::before,
    .container.right::before {
        left: 100px;
        border-color: transparent #006e51 transparent transparent;
    }

    .container.left .date,
    .container.right .date {
        right: auto;
        left: 15px;
    }

    .container.left .icon,
    .container.right .icon {
        right: auto;
        left: 146px;
    }

    .container.left .content,
    .container.right .content {
        padding: 30px 30px 30px 90px;
        border-radius: 500px 0 0 500px;
    }

    .container .content {
        width: 100%;
        border: 0;
        position: relative;
    }

    .container.left .content,
    .container.right .content {
        padding: 0;
        border-radius: 0;
    }

    .content img {
        width: 100%;
    }

    .timeline::after {
        content: '';
        position: absolute;
        width: 5px;
        top: 0;
        bottom: 1% !important;
        left: 25%;
        margin-left: 0;
        background: linear-gradient(
            180deg,
            rgba(27, 87, 44, 1) 0%,
            rgba(27, 87, 44, 1) 97%,
            rgba(255, 255, 255, 1) 100%
        ) !important;
    }

    .container::before {
        content: '';
        position: absolute;
        height: 2px;
        top: calc(50% - 3px);
        right: 8px;
        background: #1b572c;
        z-index: 1;
        width: 5.1%;
    }

    .container.left {
        left: 0;
        margin-left: 0%;
    }

    .join_buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: auto;
        margin-top: 20px;
    }

    .join_button {
        width: 260px;
        padding: 15px;
        background-color: #48874c;
        color: white;
        font-size: 18px;
        border: none;
        transition: 0.3s;
        border-radius: 10px;
        margin-bottom: 20px;
    }

    .gift_button {
        width: 260px;
        padding: 15px;
        font-size: 16px;
    }

    .about-menu {
        width: 100% !important;
    }

    .pagechanger {
        font-size: 25px !important;
    }

    .container.right {
        left: 0%;
        padding-bottom: 50px;
    }

    .container.left {
        left: 0%;
        padding-bottom: 50px;
    }

    .join {
        margin-top: 0;
    }

    .container .content {
        border: 3px solid #004413 !important;
    }

    #banner {
        height: 40vh;
        background-size: auto 100%;
        background-position-x: center;
    }

    #banner img {
        display: none;
    }

    .content {
        pointer-events: none !important;
    }
}
