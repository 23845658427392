#offBody {
    align-items: center;
}

.offPragraph {
    font-size: 16px;
    color: white;
    font-weight: 500;
    text-align: center;
    margin: 0 auto;
    padding: 10px 0px;
    background-color: #eb5b24;
}

.greenText {
    color: #66aa47;
}
.swiperstart {
    overflow: hidden;
}

@media screen and (max-width: 998px) {
    #offBody {
        display: none;
    }
}
