.letters-container {
    width: 1200px;
    margin: auto;
    text-align: center;
}
h1 {
    /* font-size: 27px; */
    font-family: 'The new Elegance';
    font-weight: bold;
    /* font-style: italic; */
}
.letters-display {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
}
.lettersNav {
    padding: 15px 0px;
}
.lettersLogo {
    width: 100px;
    margin: 0px;
    position: absolute;
    left: 0;
    bottom: -65px;
}
.lettersLogo img {
    width: 100%;
}
.lettersList ul {
    display: flex;
    list-style: none;
    margin-bottom: 0px;
    align-items: center;
}
.lettersList li {
    margin-left: 50px;
    cursor: pointer;
}
.ltbanner-content h1 {
    font-size: 35px;
}
.letters-btn1 {
    margin: auto;
    display: flex;
    align-items: center;
    width: 250px;
    height: unset !important    ;
    padding: 15px 0px !important;
    color: white;
    cursor: pointer;
}
.lettersList {
    margin: 0px;
}
.letters-sub {
    border: 2px solid #ea5b24;
    color: #ea5b24;
    padding: 10px 20px;
    border-radius: 8px;
    transition: 0.3s;
}
.letters-sub p {
    margin-bottom: 0px;
}
.letters-sub:hover {
    background-color: #ea5b24;
}
.letters-sub:hover p {
    color: white;
}
.letters-sub p {
    color: #ea5b24;
}
.ltbanner-bck {
    background: #fff8e8;
    padding: 50px 0px;
}
.popUp-letters iframe {
    width: 100% !important;
}
.ltbanner-content .button1 {
    margin: 0px !important;
}
.ltbanner-bck .letters-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.ltbanner-content {
    width: 40%;
    text-align: left;
    position: relative;
}
.lt-mob {
    display: none;
}
.letters-banner {
    width: 60%;
}
.letters-banner img {
    width: 100%;
}
.ltbanner-content a {
    background-color: #ea5b24;
    color: white;
    padding: 10px 50px;
    font-weight: bold;
    border-radius: 4px;
    text-decoration: none;
}
.ltbanner-content p {
    padding: 20px 0px;
}
.ltbanner-content h1 {
    color: #056f07;
}
.lt-absolute {
    border: 2px solid #c7cc48;
    background-color: white;

    position: absolute;
    bottom: -180px;
    display: flex;
    border-radius: 8px;
    padding: 0px 10px;
    align-items: center;
}
.lt-absolute p {
    width: 50%;
    font-family: 'The new Elegance';
    font-style: italic;
    font-weight: bold;
    margin-bottom: 0px;
    font-size: 16px;
}
.lt-absolute img {
    width: 120px;
}
.ltworks-content {
    display: flex;
    flex-direction: column;
    width: 60%;
    padding-bottom: 20px;
}
.ltworks-display {
    display: grid;
    column-gap: 40px;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 20px;
    text-align: center;
}
.ltworks-content h2 {
    font-size: 24px;
    font-family: 'The new Elegance';
    font-weight: bold;
    font-style: italic;
}
.letters-works {
    padding-top: 100px;
}
.letters-works a {
    background-color: #ea5b24;
    color: white;
    padding: 10px 50px;
    font-weight: bold;
    border-radius: 4px;
    text-decoration: none;
}
.letters-connect {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    padding: 100px 0px;
}
.letters-connect div {
    width: 49%;
}
.popup-content img {
    height: 80px !important;
    width: 80px !important;
}
.ltplan-display {
    background-color: white;
    border: 3px solid #ea5b24;
    border-radius: 8px;
    padding: 20px 30px;
    width: 100%;
    margin-bottom: 20px;
}

.ltplan-display ul {
    list-style: none;
    padding-left: 0px;
    margin-bottom: 0px;
}
.ltplan-display li {
    padding-bottom: 10px;
    font-weight: 500;
}
.per-box {
    font-size: 14px;
    color: gray;
    font-family: 'The new Elegance';
    font-weight: bold;
    font-style: italic;
}
.ltplan-month {
    text-align: center;
    font-family: 'The new Elegance';
    font-weight: bold;
    font-style: italic;
    /* border-bottom: 2px solid black; */
    display: flex;
    justify-content: space-between;
}
.ltplan-month h1 {
    text-align: center;
    font-family: 'The new Elegance';
    font-weight: bold;
    margin-bottom: 0px;
}
.ltplan-month p {
    margin-bottom: 0px;
}
.ltplan-month {
    padding-bottom: 10px;
}
.ltplan-month div {
    width: 49%;
    padding: 0px 5px;
}
.ltplan-month div:nth-child(1) {
    border-right: 2px solid black;
}
.ltplan-month h1 {
    font-size: 27px;
}
.lt-header {
    text-align: center;
    padding-bottom: 20px;
}
.lt-header p {
    margin-bottom: 0px;
}
.itplan-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 35%;
    margin: 0px;
    margin-left: 10px;
}
.lt-plan a {
    width: 100% !important;
    text-align: center;
}
.play-popUp {
    width: 35%;
}
.memebr-display {
    width: 60%;
}
.play-popUp img {
    width: 100%;
    border-radius: 10px;
}
.lt-plan .play-popUp {
    width: 40%;

    margin: 0px;
    margin-right: 10px;
}
.lt-plan img {
    width: 100%;
    border-radius: 20px;
}
.lt-plan .member-container {
    justify-content: center;
}
.hamburger {
    z-index: 9999;
    margin: 0px;
    display: none;
}
.dropdown ul,
.dropdown li {
    list-style: none;
    text-align: left;
    width: 100%;
    padding-left: 20px !important;
    padding-right: 20px !important;
    font-size: 18px;
}
.letters-sub {
    text-align: center !important;
}
.dropdown {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow-y: auto;
}

.dropdown ul {
    padding: 0;
}

.dropdown li {
    /* Add your styles for the dropdown navigation items here */
    cursor: pointer;
    padding: 10px;
}

@media screen and (max-width: 1024px) {
    .letters-container {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
    .letters-connect,
    .ltbanner-bck .letters-container {
        flex-direction: column;
    }
    .letters-connect div,
    .ltbanner-content,
    .letters-banner {
        width: 100%;
    }
    .lt-absolute {
        bottom: -560px;
    }
    .ltbanner-content {
        padding-bottom: 30px;
    }
    .lt-plan .play-popUp {
        width: 49%;
    }
    .popup-content img {
        width: 80px;
        height: 80px;
    }
    .letters-connect img {
        width: 100%;
    }
    .letters-connect h1 {
        padding-top: 10px;
    }
}
@media screen and (max-width: 768px) {
    .ltbanner-bck .letters-container,
    .letters-connect {
        flex-direction: column;
    }
    .ltbanner-content,
    .letters-banner,
    .ltworks-content,
    .letters-connect div,
    .letters-connect img {
        width: 100%;
    }
    .itplan-column,
    .lt-plan .play-popUp {
        margin-left: 0px;
        width: 90%;
    }
    .lt-plan .play-popUp {
        margin-bottom: 20px;
    }
    .letters-banner {
        padding: 50px 0px;
    }
    .lt-absolute {
        bottom: -520px;
        left: 0;
        right: 0;
    }
    .ltworks-display {
        grid-template-columns: repeat(1, 1fr);
    }
    .letters-connect h1 {
        padding-top: 10px;
    }
    .ltplan-display ul {
        font-size: 18px;
    }
    .popup-content img {
        width: 50px !important;
    }
    .ltbanner-content p {
        padding: 10px 0px;
    }
    .lt-mob {
        display: block;
    }
    .lt-des {
        display: none;
    }
    .ltbanner-content {
        padding-bottom: unset;
        text-align: center;
    }
    .ltbanner-content .button1 {
        margin: auto !important;
    }
    .lettersLogo {
        left: 20px;
        bottom: -60px;
        z-index: 99999;
    }

    .lettersList {
        display: none; /* Hide the navigation list by default on mobile */
    }

    .hamburger {
        display: block; /* Show the hamburger icon on mobile */
    }

    .showMenu {
        display: block; /* Show the navigation list when the mobile menu is active */
    }
    .popUp-letters iframe {
        height: 500px !important;
    }
    .play-popUp {
        width: 90%;
    }
    .memebr-display {
        width: 90%;
    }
}
