.join-group-div{
    width: 100%;
    padding: 40px 0;
    background-color: #EBF1E1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.facebook{
    width: 450px;
    display: flex;
    flex-direction: column;
    color: #0B3144;
    margin: 0;
    padding-right: 100px;
}
.facebook h1{
    text-align: center;
    font-size: 30px;
    margin-top: 10px;

}
.facebook img{
    width: 120px;
}
.join-group-info{
    width: 370px;
    margin: 0;
    height: auto;
    padding-left: 20px;

}
.join-group-info p{
    color: black;
    font-size: 16px;
    margin-bottom: 30px;
}
.join-group-info  a{
    background-color: #66AA47;
    border-radius: 7px;
    padding: 10px 50px;
    margin-top: 20px;
    color: white;
    text-decoration: none;
}

@media screen and (max-width:998px) {
    .join-group-div{
        flex-direction: column;
    }
    .facebook{
        padding: 0;
    }
    .join-group-info {
        width: 400px;
        margin: 0;
        height: auto;
        padding: 20px 0 0 0;
        text-align: center;
    }
}
@media screen and (max-width:600px) {
    .join-group-info {
        width: 95%;
        padding: 20px 20px 0 ;
    }
    .facebook{
        width: 95%;
    }
}