.third-section {
  width: 100%;
  max-width: 1200px;
  background-color: #fffcf8;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
}

.thirdLeft {
  width: 40%;
  padding: 40px 0;
  border-radius: 7px;
  margin: 0;
}

.thirdLeft img {
  width: 100%;
}

.thirdRight {
  display: flex;
  flex-direction: column;
  width: 45%;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0;
}

.thirdTitle {
  font-size: 32px;
  font-weight: 700;
  color: #2b3143;
  line-height: 40px;
  margin: 0 0 10px 0;
}

.thirdTitle span {
  color: #66aa47;
}

.thirdBannerPara {
  margin: 10px 0 10px 0;
  text-align: center;
  color: #2b3143;
  width: 50%;
  font-size: 18px;
}

.thirdBannerBtn {
  font-size: 18px;
  color: white;
  background-color: #48874c;
  border: none;
  border-radius: 7px;
  width: 175px;
  height: 60px;
  margin: 20px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.thirdBannerBtn:hover {

  color: white;
}

.peek-mobile {
  display: none;
}

@media screen and (max-width:1500px) {
  .thirdLeft {
    margin-left: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .thirdBannerPara {
    margin: 10px 0 10px 0;
    text-align: center;
    color: #2b3143;
    width: 80%;
    font-size: 18px;
  }

  .third-section {
    width: 100%;
    height: 460px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .thirdLeft {
    width: 45%;
    border-radius: 7px;
    margin: 0 10px 0 0;
  }
}

@media screen and (max-width: 998px) {
  .third-section {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .thirdLeft {
    width: 80%;
    border-radius: 7px;
    margin: 30px 0px 0 0;
  }

  .thirdRight {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 350px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 00px 0 10px;
  }

  .thirdTitle {
    font-size: 30px;
    font-weight: 700;
    color: #2b3143;
    line-height: 40px;
    margin: 0 0 10px 0;
  }

  .thirdTitle span {
    color: #66aa47;
  }

  .thirdBannerPara {
    margin: 10px 0 10px 0;
    text-align: center;
    color: #2b3143;
    width: 50%;
    font-size: 18px;
  }

  .thirdBannerBtn {
    font-size: 18px;
    color: white;
    background-color: #48874c;
    border: none;
    border-radius: 7px;
    width: 175px;
    height: 60px;
    margin: 20px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }

  .thirdBannerBtn:hover {
    color: white;
  }

  .peek-mobile {
    display: none;
  }

  @media screen and (max-width: 1500px) {
    .thirdLeft {
      margin-left: 20px;
    }
  }

  @media screen and (max-width: 1024px) {
    .thirdBannerPara {
      margin: 10px 0 10px 0;
      text-align: center;
      color: #2b3143;
      width: 80%;
      font-size: 18px;
    }

    .thirdBannerMainDiv {
      width: 100%;
      height: 460px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .thirdLeft {
      width: 45%;
      border-radius: 7px;
      margin: 0 10px 0 0;
    }
  }

  @media screen and (max-width: 998px) {
    .thirdBannerMainDiv {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      /* padding: 0 5%; */
    }

    .thirdLeft {
      width: 80%;
      border-radius: 7px;
      margin: 30px 0px 0 0;
    }

    .thirdRight {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 350px;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 0 00px 0 10px;
    }

    .thirdTitle {
      font-size: 30px;
      font-weight: 700;
      color: #2b3143;
      line-height: 40px;
      margin-bottom: 10px;
    }

    .thirdBannerPara {
      margin: 10px 0 10px 0;
      text-align: center;
      color: #2b3143;
      width: 90%;
      font-size: 16px;
    }

    .thirdBannerBtn {
      font-size: 18px;
      color: white;
      background-color: #48874c;
      border: none;
      border-radius: 7px;
      width: 162px;
      height: 53px;
      margin: 20px 0 0 0;
      text-decoration: none;
    }

    .thirdBannerBtn {
      font-size: 18px;
      color: white;
      background-color: #48874c;
      border: none;
      border-radius: 7px;
      width: 162px;
      height: 53px;
      margin: 20px 0 0 0;
    }
  }
}