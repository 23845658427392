@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,500&display=swap');

* {
    margin: 0 auto;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}

body {
    font-family: 'Poppins', sans-serif;
    background-color: #fffcf8;
    /* overflow-y: scroll !important; */
    overflow-x: hidden !important;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
} */
#root {
    overflow-x: hidden;
}

#freeButler {
    display: none !important;
}

.freeButlers {
    display: none !important;
}

.terms-of-services {
    background-color: #fffcf8;
}

.terms-div {
    max-width: 1100px;
    padding: 0 30px 100px;
}

.terms-div h1 {
    padding: 50px 0 25px;
}

.terms-div h2 {
    padding: 25px 0;
}

.terms-div p {
    font-size: 15px;
}

.privacy-policy {
    background-color: #fffcf8;
}

.privacy-div {
    max-width: 1100px;
    padding: 0 30px 342px;
}

.privacy-div h1 {
    padding: 50px 0 25px;
}

.cookies-policy {
    background-color: #fffcf8;
}

.cookies-div {
    max-width: 1100px;
    padding: 0 30px 342px;
}

.cookies-div h1 {
    padding: 50px 0 25px;
}

.cookies-div p {
    font-size: 15px;
}

.privacy-div p {
    font-size: 15px;
}

.shipping-costs {
    background-color: #fffcf8;
}

.shipping-div {
    max-width: 1100px;
    padding: 0 30px 100px;
}

.shipping-div h1 {
    padding: 50px 0 25px;
}

.shipping-div h2 {
    padding: 25px 0;
}

.shipping-div p {
    font-size: 15px;
}

.refund-policy {
    background-color: #fffcf8;
}

.refund-div {
    max-width: 1100px;
    padding: 0 30px 100px;
}

.refund-div h1 {
    padding: 50px 0 25px;
}

.refund-div h2 {
    padding: 25px 0;
}

.refund-div p {
    font-size: 15px;
}

.faqs {
    background-color: #fffcf8;
}

.faqs-div {
    max-width: 1100px;
    padding: 0 30px 100px;
}

.faqs-div h1 {
    padding: 50px 0 25px;
}

.faqs-div h2 {
    font-size: 22px;
    font-weight: 500;
    width: 100%;
    padding: 25px 50px;
    margin: 0;
}
.transition {
    font-weight: 500;
    width: 100%;
    padding-top: 10px;
    transition: all 2s ease;
    transition-delay: 2s;
}
.answer {
    padding: 0px 50px 50px 50px;
}
.faqs-div p {
    font-size: 15px;
    margin: 0;
    color: gray;
}
.faq-color {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 24px rgb(0 0 0 / 12%);
    margin-bottom: 20px;
}
.faq-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.faq-icon svg {
    color: #66aa47;
    width: 3%;
    height: 2%;
    margin: 0px 50px;
}
.transition {
    animation: fade-in 2.2s;
    transition-delay: 2s;
}
@keyframes fade-in {
    from {
        max-height: 0px;
        -webkit-transition: max-height 2s ease-in-out;
        overflow: hidden;
        transition-delay: 2s;
    }

    to {
        max-height: 700px;
        -webkit-transition: max-height 2s ease-in-out;
        transition-delay: 2s;
    }
}
/* .cont.ensureChristmas-navbar {
  display: none !important;
} */

.onLeave-popup {
    width: 480px;
    height: 460px;
    /* border: 1px solid red; */
    position: fixed;
    margin: 0 auto;
    /* background-color: red; */
    right: calc(50% - 250px);
    top: calc(50% - 250px);
    z-index: 999999;
    border-radius: 20px;
    /* background-image: url(./assets/pop-up.png); */
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: 100% 45%;
    background-color: white;
}

.popup-overlay {
    background-color: #0000001c;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999999;
}

.onLeave-popup svg {
    color: white;
}

.popup-button {
    width: 98%;
    height: 40px !important;
    border-radius: 4px;
    border: none;
    margin: 20px 0;
    background-color: #ea5b24;
    color: white;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.popup-email {
    padding-bottom: 15px;
    height: fit-content !important;
    margin-top: 10px;
}

.onLeave-popup p {
    margin-top: 240px;
    text-align: center;
    width: 90%;
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 30px;
    color: black;
}

iframe#webWidget {
    z-index: 999999999999 !important;
}

.small-popup-email {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    justify-content: flex-start;
}

.small-popup-email form {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
}

.small-popup-email input {
    width: 63%;
    border: 1px solid #66aa47;
    border-radius: 7px 0px 0px 7px;
    height: 40px;
    background-color: #66aa4754;
    justify-content: flex-start;
    margin: 0;
    color: white;
    padding-left: 5px;
}

.small-popup-emai input::placeholder {
    color: white !important;
}

.small-popup-input::placeholder {
    color: #66aa47 !important;
}

.small-popup-button {
    height: 40px;
    margin: 0 !important;
    padding: 0 !important;
    width: 30%;
    border-radius: 0 7px 7px 0 !important;
}

.small-popup-button-mobile {
    display: none;
}

.main-loader {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    z-index: 99999999999;
    background-color: #fffcf8;
}

.main-loader img {
    width: 200px;
}

.subscribe-main-wrapper {
    /* max-width: 1200px; */
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 900px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fffcf8;
    /* filter: blur(5px); */
}

.subscribe-card {
    max-width: 900px;
    width: 100%;
    display: flex;
    align-items: center;
    height: fit-content;
    background-color: #ffffff;
    border-radius: 20px;
    padding: 20px;
}

.subscribe-subscribe form {
    justify-content: space-between !important;
}

.subscribe-subscribe {
    width: 320px;
}

.subscribe-subscribe button {
    margin: 0;
}

.subscribe-email-address {
    color: #1b6047;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 10px auto;
    font-size: 22px;
    text-align: left;
    font-family: Playfair Display;
}

.subscribe-card-text {
    width: 45%;
}

.subscribe-card-text p {
    color: #686868;
    font-size: 16px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    margin: 10px 0;
}

#email-subscribe {
    width: 100% !important;
}

.footer-submit-button {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #1b6047;
    border-left: 1px solid black !important;
    padding-left: 10px;
}

.page404 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f9fcf8;
    padding: 150px 0;
}

.page404 p {
    color: #182f43;
    font-size: 24px;
    font-weight: 400;
    text-align: center;
}

.page404 a {
    padding: 10px 65px;
    background-color: #66aa47;
    color: white;
    text-decoration: none;
    border-radius: 10px;
    box-shadow: 0px 11px 14px rgba(102, 170, 71, 0.23);
}

/* mobileee */

@media screen and (max-width: 998px) {
    .small-popup-button-desktop {
        display: none;
    }

    .small-popup-button-mobile {
        display: block;
        padding: 0 !important;
        width: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .small-popup-email input {
        width: 75%;
    }

    .freeButlers {
        display: flex !important;
    }

    /* 
  .offPragraph {
    display: none;
  } */

    .ensureChristmas {
        display: none;
    }

    .navBar-countdown {
        background-color: #668e5b;
        display: flex;
        flex-direction: row;
    }

    .ensureChristmas-navbar {
        background-color: #668e5b;
        flex-direction: row;
        /* display: flex !important; */
        align-items: center;
        justify-content: center;
    }

    .days,
    .hours,
    .minutes,
    .sec h1 {
        padding-top: 2px;
    }

    .days,
    .hours,
    .minutes,
    .sec {
        width: 60px !important;
        height: 60px !important;
        justify-content: center !important;
    }

    .counter h1 {
        /* font-size: 30px; */
        font-size: 18px !important;
    }

    .counter p {
        /* font-size: 12px; */
        font-size: 7px;
    }

    .ensure {
        /* font-size: 16px; */
        font-size: 12px !important;
    }

    .ensureChristmas-navbar {
        padding: 0 15px;
        /* display: flex; */
    }

    #popup-email {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 260px;
        border: 1px solid #707070;
        border-radius: 10px;
        height: 40px;
    }

    #popup-email input {
        height: 100%;
        border-radius: 4px;
        border: none;
        padding: 6px 0px 0px 7px;
        font-size: 16px;
        line-height: 1;
        outline: none;
        box-shadow: none;
        transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        width: 100%;
        border-radius: 10px;
    }

    #popup-email .filled {
        transform: translate(0, 4px) scale(0.8);
        left: 5px;
    }

    #popup-email label {
        position: absolute;
        pointer-events: none;
        transform: translate(0, 14px) scale(1);
        transform-origin: top left;
        transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        color: #6f81a5;
        font-size: 12px;
        line-height: 1;
        left: 5px;
    }
    .faq-icon svg {
        width: 5%;
    }
}

@media screen and (max-width: 768px) {
    .subscribe-card img {
        width: 60%;
        margin-right: 15%;
    }
    .ensureChristmas {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
    }
    .subscribe-card {
        flex-direction: column-reverse;
    }

    .subscribe-main-wrapper {
        height: fit-content;
        padding-bottom: 100px;
        margin-top: 190px;
    }

    h1.subscribe-email-address {
        font-size: 20px;
    }

    .subscribe-card-text p {
        font-size: 14px;
    }

    .subscribe-card-text {
        width: 100%;
    }

    .subscribe-email-address,
    .subscribe-card-text p {
        width: 100% !important;
        text-align: center;
    }
    .faqs-div h2 {
        font-size: 18px;

        padding: 15px;
    }
    .answer {
        padding: 0px 15px 15px 15px;
    }
    .faq-icon svg {
        color: #66aa47;
        width: 11%;
        height: 2%;
        margin: 0px 20px;
    }
    .faqs-div {
        width: 100%;
        padding: 0px 20px;
    }
}

@media screen and (max-width: 600px) {
    .onLeave-popup {
        width: 580px;
        height: 416px;
        right: calc(50% - 290px);
        top: calc(50% - 225px);
        background-image: url(./assets/pop-up-mobile.png);
        background-repeat: no-repeat;
        background-size: 100% 50%;
    }

    .onLeave-popup p {
        text-align: center;
        width: 95%;
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 20px;
        color: black;
    }

    .emailAddress {
        width: 100%;
    }

    #popup-email {
        width: 90%;
        margin-bottom: 15px;
    }

    .popup-button {
        width: 90%;
    }

    .popup-valid-email {
        font-size: 11px;
        position: absolute;
        left: 5%;
        bottom: 70px;
    }
}

@media screen and (max-width: 500px) {
    .onLeave-popup {
        width: 480px;
        right: calc(50% - 240px);
    }
}

@media screen and (max-width: 429px) {
    .onLeave-popup {
        width: 400px;
        right: calc(50% - 200px);
    }
}

@media screen and (max-width: 375px) {
    .onLeave-popup {
        width: 350px;
        right: calc(50% - 175px);
    }
}
