.plans-carousel {
    width: 100%;
    height: 500px;
    align-items: center;
    text-align: center;
    margin: 50px 0;
}

.past-box1 {
    width: 450px;
    height: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.past-box1-img {
    width: 100%;
    height: 90%;
    // background-image: url(../../../../assets//Plans/Carousel/box.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;

}

.past-box1-img img {
    width: 100%;
    height: 100%;
}

.past-box1-title {
    width: 100%;
    height: 10%;
    background-color: #66AA47;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;

}

.past-box1-title p {
    font-size: 25px;
    position: absolute;
    font-family: "Kells";
    color: white;
    height: 40px;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}



#carousel {
    position: relative;
    height: 500px;
    // max-width: 1800px;
    width: 100%;
    margin: 50px auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;

}

.level0 p {
    @media screen and (max-width:998px) {

        font-size: 22px;

    }


}

.level1 p,
.level-1 p {
    font-size: 20px;

    @media screen and (max-width:998px) {

        font-size: 15px;

    }

    @media screen and (max-width:600px) {
        font-size: 12px;
        display: none;

    }
}

.level2 p,
.level-2 p {
    font-size: 18px;
}

.arrow {
    position: absolute;
    width: 35px;
    height: 35px;
    background-color: #66AA47;
    text-align: center;
    font-size: 25px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 20px;
    color: #228291;
    line-height: 30px;
    margin-top: 85px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.arrow-right {
    right: calc(34% - 35px);
}

.arrow-left {
    left: calc(34% - 35px);
}

.item {
    text-align: center;
    color: white;
    font-size: 40px;
    position: absolute;
    transition: height 1s, width 1s, left 1s, margin-top 1s, line-height 1s, background-color 1s;


}

$level2-height: 390px;
$level2-width: 16%;
$level1-height: 440px;
$level1-width: 18%;
$level0-height: 490px;
$level0-width: 32%;



// $level-2-left: 650px;
// $level-1-left: 500px;
// $level0-left: 330px;
// $level1-left: 180px;
// $level2-left: 50px;

$level-2-left: 84%;
$level-1-left: 66%;
$level0-left: 34%;
$level1-left: 16%;
$level2-left: 0;




.level-2 {
    height: $level2-height;
    width: $level2-width;
    line-height: $level2-height;
    background-color: #228291;
    left: $level-2-left;
    margin-top: 25px;
    opacity: 0.6;

    @media screen and (max-width:998px) {
        display: none;
    }
}

.level-1 {
    height: $level1-height;
    width: $level1-width;
    line-height: $level1-height;
    background-color: #6796E5;
    left: $level-1-left;
    margin-top: 10px;
    opacity: 0.8;
}

.level0 {
    height: $level0-height;
    width: $level0-width;
    line-height: $level0-height;
    background-color: #4EC9E1;
    left: $level0-left;
}

.level1 {
    height: $level1-height;
    width: $level1-width;
    line-height: $level1-height;
    background-color: #6796E5;
    margin-top: 10px;
    left: $level1-left;
    opacity: 0.8;
}

.level2 {
    height: $level2-height;
    width: $level2-width;
    line-height: $level2-height;
    background-color: #228291;
    margin-top: 25px;
    left: $level2-left;
    opacity: 0.6;

    @media screen and (max-width:998px) {
        display: none;
    }
}

.left-enter {
    opacity: 0;
    left: $level2-left - $level2-width;
    height: $level2-height - 30;
    width: $level2-width - 20;
    line-height: $level2-height - 30;
    margin-top: 40px;
}

.left-enter.left-enter-active {
    opacity: 1;
    left: $level2-left;
    height: $level2-height;
    width: $level2-width;
    line-height: $level2-height;
    margin-top: 25px;
    transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
}

.left-leave {
    opacity: 1;
    left: $level-2-left;
    height: $level2-height;
    width: $level2-width;
    line-height: $level2-height;
    margin-top: 25px;
}

.left-leave.left-leave-active {
    left: $level-2-left + $level2-width + 20;
    opacity: 0;
    height: $level2-height - 30;
    line-height: 120px;
    margin-top: 40px;
    width: $level2-width - 20;
    transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
}

.right-enter {
    opacity: 0;
    left: $level-2-left + $level2-width;
    height: $level2-height - 30;
    width: $level2-width - 20;
    line-height: $level2-height - 30;
    margin-top: 40px;
}

.right-enter.right-enter-active {
    left: $level-2-left;
    opacity: 1;
    height: $level2-height;
    margin-top: 25px;
    line-height: $level2-height;
    width: $level2-width;
    transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
}

.right-leave {
    left: $level2-left;
    height: $level2-height;
    opacity: 1;
    margin-top: 25px;
    line-height: $level2-height;
    width: $level2-width;
}

.right-leave.right-leave-active {
    left: $level2-left - $level2-width;
    opacity: 0;
    height: $level2-height - 30;
    width: $level2-width - 20;
    line-height: $level2-height - 30;
    margin-top: 40px;
    transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
}

.noselect {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@media screen and (max-width:998px) {

    $level1-width: 26%;
    $level0-width: 48%;

    $level-1-left: 0%;
    $level0-left: 26%;
    $level1-left: 74%;

    .level0 {
        height: $level0-height;
        width: $level0-width;
        line-height: $level0-height;
        background-color: #4EC9E1;
        left: $level0-left;
    }

    .level1 {
        height: $level1-height;
        width: $level1-width;
        line-height: $level1-height;
        background-color: #6796E5;
        margin-top: 10px;
        left: $level1-left;
        opacity: 0.8;
    }

    .level-1 {
        height: $level1-height;
        width: $level1-width;
        line-height: $level1-height;
        background-color: #6796E5;
        left: $level-1-left;
        margin-top: 10px;
        opacity: 0.8;
    }
}

@media screen and (max-width:600px) {

    $level1-width: 10%;
    $level0-width: 80%;


    $level-1-left: 0%;
    $level0-left: 10%;
    $level1-left: 90%;

    .level0 {
        height: $level0-height;
        width: $level0-width;
        line-height: $level0-height;
        background-color: #4EC9E1;
        left: $level0-left;
    }

    .level1 {
        height: $level1-height;
        width: $level1-width;
        line-height: $level1-height;
        background-color: #6796E5;
        margin-top: 10px;
        left: $level1-left;
        opacity: 0.3;
    }

    .level-1 {
        height: $level1-height;
        width: $level1-width;
        line-height: $level1-height;
        background-color: #6796E5;
        left: $level-1-left;
        margin-top: 10px;
        opacity: 0.3;
    }

    .arrow-right {
        right: calc(10% - 35px);
    }

    .arrow-left {
        left: calc(10% - 35px);
    }
}