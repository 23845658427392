@font-face {
  font-family: 'Kells';
  src: url('../Fonts/kellsRound.woff') format('woff');
}

.peek-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

#smain-subtitle {
  font-size: 18px;
  color: #1b6047;
  font-family: 'Kells';
  font-weight: bold;
  margin-top: 30px;
}

.main-title {
  color: #0b3144;
  font-size: 40px;
  font-weight: bold;
  margin-top: 20px;
}

.main-paragraph {
  width: 50%;
  text-align: center;
  font-size: 16px;
  color: black;
}

.posts {
  width: 50%;
  height: fit-content;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  row-gap: 20px;
}

.post {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 300px;
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.peek-main a {
  text-decoration: none;
  color: #0b3144;
}

.post a {
  width: 100%;
  text-decoration: none;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.post a * {
  text-align: left;
  width: 100%;
}

.post h1 {
  font-size: 25px;
  font-family: 'Kells';
  text-decoration: none;
  font-weight: 400;
  color: #ffffff;
}

.post p {
  font-size: 15px;
  color: white;
}

.join {
  width: 100%;
  background-color: #66aa4740;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.join p {
  width: 25%;
}

.peek-buttons {
  display: flex;
  flex-direction: row;
  width: 18%;
}

.claim {
  width: 150px;
  background-color: #66aa47;
  padding: 10px 10px;
  border-radius: 10px;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.claim svg {
  padding-left: 5px;
}

.gift {
  width: 150px;
  background-color: white;
  padding: 10px 10px;
  border-radius: 10px;
  color: #66aa47;
  border: 1px solid #66aa47;
}

.join a {
  text-decoration: none;
  margin: 0 5px;
}

.join p {
  font-size: 16px !important;
  padding: 10px 0px;
  width: 100% !important;
}

.peek-main {
  margin-top: 43px;
}

.posts {
  margin-top: 20px;
}

.post svg {
  padding: 0;
  width: auto;
  height: 25px;
  margin: 0 0 -8px 10px;
}


@media screen and (max-width: 998px) {
  .posts {
    grid-template-columns: repeat(2, 1fr);
  }

}

@media screen and (max-width: 767px) {

  .main-title {
    text-align: center;
  }

  .main-paragraph {
    width: 94%;
  }

  .posts {
    width: 95%;
    grid-template-columns: repeat(1, 1fr);
    margin: 35px 0;
  }

  .peek-buttons {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .join a {
    text-decoration: none;
    margin: 10px 0;
  }

  .join p {
    width: 100%;
  }

  .claim {
    width: 150px;
    background-color: #66aa47;
    padding: 10px 10px;
    border-radius: 10px;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .claim svg {
    padding-left: 5px;
  }

  .gift {
    width: 150px;
    background-color: white;
    padding: 10px 10px;
    border-radius: 10px;
    color: #66aa47;
    border: 1px solid #66aa47;
  }

  .join a {
    text-decoration: none;
    margin: 0 5px;
  }

  .join p {
    font-size: 16px !important;
    padding: 10px 0px;
    width: 100% !important;
  }

  .join p {
    font-size: 14px;
    padding: 0 30px;
  }

  .join h2 {
    font-size: 25px !important;
  }

  #main-subtitle {
    margin-top: 20px;
  }

  .post svg {
    padding: 0;
    width: auto;
    height: 25px;
    margin: 0 0 -8px 10px;
  }


  .offPragraph {
    padding: 15px;
  }

  #offBody {
    height: auto;
  }

  .post-main-banner-title {
    font-size: 20px !important;
    padding-top: 10px !important;
  }

  p.has-text-align-center:nth-child(2) {
    padding-bottom: 15px !important;
  }

  .join a {
    margin: 0px 0px !important;
  }

  .join p {
    padding: 5px 30px 5px 30px !important;
  }

  .join h2 {
    padding: 0px 0px 10px 0px !important;
  }
}

