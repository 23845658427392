.fbMain {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 100px 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 50px 0 00px 0;
}

.fbMainText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    color: white;
    width: 27%;
    margin: 0 100px 0 0;
}

.fbMainText div {
    margin: 0;
}

.fbMainText p,
.fbMainText h1 {
    margin: 5px 0;
    width: 100%;
}

.fbMainText h1 {
    font-family: 'The new Elegance';
    /* font-style: italic; */
    font-weight: bold;
    font-size: 27px;
    letter-spacing: 1px;
}

.fbMainText p {
    font-size: 18px;
}

.fbMainButtons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 300px;
}

.fbMainButtons a {
    margin: 5px 0;
    background-color: #48874c;
    color: white;
    text-decoration: none;
    width: 220px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 12px 0;
    border-radius: 10px;
}

@media screen and (max-width: 998px) {
    .fbMain {
        flex-direction: column;
        align-items: center;
    }

    .fbMainText {
        width: 70%;
        margin: 0;
    }

    .fbMainText p,
    .fbMainText h1 {
        text-align: center;
    }

    .fbMainButtons {
        margin: 20px 0 0 0;
    }
}

@media screen and (max-width: 600px) {
    .fbMainText {
        width: 95%;
    }

    .fbMainText h1 {
        font-size: 25px;
    }

    .fbMainText p {
        font-size: 16px;
    }
}
