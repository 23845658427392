.seventhBanner {
    padding: 50px 0px;
    background-color: #fffcf8;
    width: 1200px;
    margin: 0 auto;
}

.seventhBanner h2 {
    color: #0b3144;
    text-align: center;
    font-family: 'The new Elegance';
    font-style: italic;
    font-weight: bold;
    font-size: 27px;
    letter-spacing: 1px;
    padding: 50px;
}

.upperimages {
    display: flex;
    padding-bottom: 50px;
    justify-content: space-between;
}

.underimages {
    display: flex;
    justify-content: space-between;
}

.upperimages img,
.underimages img {
    max-width: 225px;
    max-height: 70px;
    margin: 0;
}

#seventhBanner-desktop {
    display: block;
}

#seventhBanner-mobile {
    display: none;
}

@media screen and (max-width: 1440px) {
    .featuredImgs {
        width: 80%;
    }
}

@media screen and (max-width: 998px) {
    .seventhBanner {
        padding: 50px 0 0px 0;
        background-color: #fffcf8;
    }
    div#seventhBanner-mobile img {
        height: 75px;
        width: 25% !important;
    }
    #seventhBanner-desktop {
        display: none;
    }

    #seventhBanner-mobile {
        display: block;
    }

    .footer-container {
        display: flex;
        flex-direction: column;
    }

    .row {
        display: flex;
        list-style: none;
        padding-bottom: 50px;
        flex-direction: column;
    }

    #col1 {
        width: 100% !important;
        text-align: center;
        padding-top: 30px;
        padding-right: 0px !important;
    }

    .irish-paragraph {
        padding-top: 50px;
        font-size: 16px;
        text-align: center !important;
    }

    #col2 {
        width: 100% !important;
        text-align: center;
        padding-top: 30px;
    }

    #col3 {
        width: 100% !important;
        text-align: center;
        padding-top: 30px;
    }

    #col4 {
        width: 100% !important;
        text-align: center;
        padding-top: 30px;
    }

    .email-signup {
        font-size: 16px;

        text-align: center !important;
    }

    .socials {
        display: flex;
        list-style: none;
        padding-top: 10px;
        justify-content: center;
    }

    .upperimages {
        display: block;
    }

    /* #col4>ui:nth-child(2)>div:nth-child(1) {
    display: flex;
    padding: 0px;
    margin: 0px;
    background-color: #66aa4754;
    color: #1b6047;
    padding: 10px;
    border: 1px solid #66aa47;
    border-radius: 5px;
    width: 280px;
    margin: 0 auto !important;
  } */
}

@media (max-width: 767px) {
    #seventhBanner-desktop {
        display: none !important;
    }

    #seventhBanner-mobile {
        display: block;
    }

    .seventhBanner h2 {
        color: #0b3144;
        text-align: center;
        font-weight: 900;
        padding: 0;
        font-size: 30px;
    }

    .upperimages {
        display: flex;
        padding-bottom: 50px;
        justify-content: space-evenly;
    }

    .underimages {
        display: flex;
        justify-content: space-evenly;
    }

    .upperimages img {
        max-width: 225px;
        max-height: 70px;
        margin: 0;
    }

    .underimages img {
        max-width: 225px;
        max-height: 70px;
        margin: 0;
    }

    #seventhBanner-desktop {
        display: block;
    }

    /* #mainDiv-mobile>div:nth-child(2) {
    margin-left: 0%;
  } */
}
