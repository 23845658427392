.fourthBannerDiv {
  background-color: #fffcf8;
  width: 100%;
  display: flex;
  max-width: 1500px;
  align-items: center;
  justify-content: center;
}

.ensureChristmas {
  padding-top: 20px;
  flex-direction: column;
  align-items: center;
}

.fourth-heading {
  text-align: center;
  padding: 50px;
}

.fourthSectionSocialMedia {
  width: 90%;
  margin: 0 auto;
}

.fourthSquare {
  margin: 50px 0;
  padding: 100px 60px;
  text-align: center;
  background: #FFFFFF;
  box-shadow: 0px 10px 10px #0000001a;
  width: 50%;
  border-radius: 20px;
}

.limitedbtn {
  background-color: #eb5b24;
  padding: 15px;
  width: 60%;
  border: none;
  border-radius: 8px;
  color: white;
  font-size: 18px;
  transition: 0.3s;
}

.limitedbtn:hover {
  background-color: #48874c;
}

.one-card {
  padding-top: 25px;
  text-align: center;
  width: 800px !important;
  margin-top: 50px;
  padding-bottom: 20px;
  display: flex !important;
  flex-direction: row-reverse;
  width: 100%;
  background: white;
  box-shadow: 5px 10px 10px 0px rgb(0 0 0 / 2%);
  border-radius: 20px !important;
  justify-content: center;
  width: 100%;
  padding: 0;
  display: flex !important;
  flex-direction: column;
  width: 450px !important;
  box-shadow: -2px 10px 10px #0000001a;
}

.fourthsection-homepage-slider {
  width: 42%;
  margin: 0;
  background-color: transparent;
  margin-left: -100px;
}

.card-info {
  width: 300px;
  height: auto;
  padding-top: 50px;
  width: unset;
  height: auto;
  padding-top: 50px;
  background-color: white;
  padding: 50px 20px 50px 20px;
  box-shadow: whitesmoke;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 10px;
  border-radius: 20px;
  margin-top: -50px;
  padding: 20px !important;
  margin: 0 !important;
  height: auto !important;
  width: 100% !important;
}

.thecards {
  display: flex;
  flex-direction: row;
}

.fourth-heading p {
  padding-top: 10px;
  padding-bottom: 20px;
  color: #0b3144;

}

.profile-image {
  width: 100px;
  height: 100px;
  padding: 0 !important;
  margin: 0 !important;
  width: 50% !important;
  height: 100% !important;
  height: 330px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100% !important;
}

.fourth-heading h2 {
  color: #0b3144;
}

.fourthBannerDiv .slick-track {
  display: flex;
}

.fourthBannerDiv .slick-track .slick-slide {
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.profile-image img {
  border-radius: 20px;
  height: 100%;
  width: 100% !important;
}

.card-info strong {
  color: #0b3144;
}

.fourthBannerDiv p {
  color: #0b3144;
}

.mobile-image {
  display: none !important;
}

.slick-current {
  opacity: 1 !important;
}

.fourthBannerDiv .slick-list {
  margin: 0 -15px !important;
  padding: 0 0 10px 0;
}

.fourthBannerDiv .slick-slide>div {
  margin: 0 15px !important;
}

.slick-arrow {
  background-color: #66aa47;
  left: 50px;
  border-radius: 100%;
  padding: 15px;
  width: 50px;
  height: 50px;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.slick-arrow img {
  margin: auto;
  width: 100%;
}

.slick-arrow::before {
  content: "";
}

.slick-next {
  left: 30% !important;
  top: 100% !important;
  display: flex !important;
}

.slick-prev {
  left: 30% !important;
  top: 100%;
  display: flex !important;
}

.slick-list,
.slick-slider,
.slick-track {
  height: 100%;
  padding: 0 0 10px 0;
}

.slick-next:hover,
.slick-prev:hover {
  background-color: #66aa47;
}

.fourthSquare {
  margin-left: 20px;
}

.fourthsection-homepage-slider {
  width: 635px;
  margin: 0;
  margin-left: -100px;
}


/* TABLET STYLING */
@media screen and (max-width: 998px) {
  .fourthBannerDiv {
    flex-direction: column;
    padding-bottom: 0px !important;
  }

  .fourth-heading p {
    margin: 0 auto;
  }

  .desktop-image {
    display: none !important;
  }

  .mobile-image {
    display: block !important;
  }

  .profile-image img {
    height: 285px;
    width: 90%;
    border-radius: 20px;
  }

  .fourthBannerDiv p {
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 0 20px;
  }

  .slick-prev {
    left: 10% !important;
    top: 50% !important;
  }

  .slick-next {
    left: 75% !important;
    top: 50% !important;
  }

  .one-card {
    height: 100 !important;
    height: 570px !important;
    width: 70% !important;
  }

  .slick-slide div {
    height: 100%;
  }

  #secondBannerDiv-mobile .bPlanImg {
    height: 60% !important;
  }

  .bPlanImg {
    margin-top: 15px;
  }

  .fourthSquare {
    width: 600px;
    margin: 30px 0;
  }

  .fourthsection-homepage-slider {
    width: 90%;
    margin: 0;
  }
}

/* MOBILE STYLING */
@media (max-width: 767px) {
  .fourth-heading {

    padding: 30px 0px;
  }

  .fourth-heading h2 {
    width: 100%;
    text-align: center;
    padding: 0 5px;
  }

  .fourth-heading p {
    margin: 0px 30px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    width: 100%;
    padding: 10px 8px;
    flex-direction: column;
  }

  .newtxtpgr {
    line-height: 25px;
  }

  .fourthSquare {
    width: 90%;
    margin: 30px auto;
    padding: 0;
    text-align: center;
  }

  .limitedbtn {
    background-color: #EB5B24;
    padding: 10px;
    width: 85%;
    border: none;
    border-radius: 8px;
    color: white;
    font-size: 18px;
    margin: 10px;
    margin-bottom: 20px;
  }

  .thecards {
    width: auto;
  }

  .one-card {
    width: 100% !important;
    height: 100% !important;
  }

  .profile-image {
    width: 70px;
    height: 80px;
  }

  .card-info {
    width: 100%;
    padding-top: 20px;
    width: unset;
    height: 100% !important;
    padding-top: 50px;
    background-color: white;
    padding: 10px 50px 50px 50px;
    box-shadow: whitesmoke;
    margin: 0 10px;
    border-radius: 20px;
    margin-top: -50px;
  }

  .card-info strong {
    margin-top: 35px;
  }

  .profile-image img {
    height: 315px;
    width: 90%;
    border-radius: 20px;
  }

  .slick-next {
    left: 84% !important;
    right: 0;
    top: 62% !important;
  }

  .slick-prev {
    left: 2% !important;
    display: block !important;
    top: 62% !important;
  }
}