.wp-block-columns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.wp-block-column {
    width: 50%;
    /* padding: 50px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
}
.flipbook-container img {
    width: 100%;
    height: 100%;
}
.textSwipeMob {
    width: 90%;
    margin: auto;
    /* padding-top: 40px; */
    display: none;
}
.singlePage {
    background-color: #fffcf8;
    width: 100%;
    height: 100%;
    padding: 20px;
    padding-left: 0px;
    padding-right: 50px;
}
.contentPage {
    display: flex !important;
    text-align: left !important;
    flex-direction: column;
    justify-content: center;
    position: relative;
    height: 100%;
}
.desPage {
    display: block;
}
.mobPage {
    display: none;
}
.contentPage h3 {
    font-size: 18px;
    font-weight: 700;
    text-align: left !important;
    margin: 0px;
}
.contentPage p {
    font-size: 15px;
    padding-bottom: 30px;
    margin: 0px;
}
.pageTrust {
    display: flex;
    align-self: center;
    gap: 5px;
    width: 100%;
}
.page-animation {
    position: absolute;
    right: -60px;
    bottom: -90px;
    width: fit-content;
}
.swipe-controls {
    padding-bottom: 50px;
    position: relative;
    padding-top: 60px;
}
.flipbook {
    margin-bottom: -5px;
}
.swipe-controls p {
    background-color: #86a141;
    color: white;
    padding: 2px 20px;
    border-radius: 50px;
    margin-top: -50px;
    margin: auto;
    width: fit-content;
}
.contentPage img {
    width: 100px;
    margin: 0px;
}
.contentPage div p {
    padding: 0px;
}
.contentPage h2 {
    font-size: 30px;
    color: #d34a2f;
    font-weight: 700;
    font-family: 'The New Elegance';
    padding-bottom: 20px;
}
.controls {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    width: 300px;
    padding: 20px 0px;
}
.controls button {
    border: none;
    background-color: #48874c;
    color: white;
    padding: 5px 20px;
    border-radius: 50px;
}
.post:hover p {
    background: #eb5b24 !important;
    transition: 0.3s;
}

.post-column-img img {
    padding: 30px;
}

.post-message {
    background-color: #66aa4740;
    max-width: unset !important;
}

.wp-block-columns {
    max-width: 1100px;
}

.wp-block-column.post-main-title-column {
    width: 100%;
}

p.post-inner-mini-title {
    font-weight: bold;
}

.post-message-text {
    text-align: center;
}

.post-message-title {
    text-align: center;
}

.aligncenter.size-full {
    display: flex;
}

/* Inline #24 | http://localhost:3000/relaxing-at-the-cliffs-of-moher */

.has-inline-color {
    text-align: center;
}

.wp-block-column.post-main-banner-left-col {
    text-align: center;
}

.has-inline-color {
    color: #48874c !important;
}

.has-text-align-center.post-main-banner-subtitle span {
    color: #2b3143 !important;
    font-family: 'Kells' !important;
    font-size: 51px;
}

.has-text-align-center.post-main-banner-subtitle {
    color: #2b3143 !important;
    font-family: 'Kells' !important;
}

.post-main-banner-paragraph {
    color: #2b3143 !important;
    font-size: 16px;
    font-weight: 400;
}

.wp-block-button.post-join-button {
    background-color: #48874c !important;
    padding: 0 !important;
    border-radius: 7px;
    text-decoration: none;
    color: white;
    height: 100%;
    width: auto;
    display: flex;
}

.post-main-banner-image {
    align-items: center;
    place-content: center;
    display: flex;
}

.wp-block-button.post-gift-button {
    background-color: #48874c !important;
    padding: 0 !important;
    border-radius: 7px;
    text-decoration: none;
    color: white !important;
    height: 100%;
    width: auto;
    display: flex;
}

.wp-block-button__link {
    text-decoration: none;
    color: white;
    height: 100%;
    padding: 10px 20px !important;
    width: 100%;
}

.wp-block-button__link:hover {
    color: white;
}

.wp-block-buttons {
    color: white;
    height: 100% !important;
}

.wp-block-button.post-gift-button a {
    color: white;
}

.post-gift a {
    color: #2b3143;
}

.wp-block-columns {
    margin-top: 33px;
}

/* Inline #19 | http://localhost:3000/relaxing-at-the-cliffs-of-moher */

body {
    /* background: #ffffff; */
    background: #fffcf8;
}

#single-box-old-boxes {
    display: none;
}

/* Inline #24 | http://localhost:3000/relaxing-at-the-cliffs-of-moher */

.wp-block-columns.post-main-banner-buttons {
    justify-content: center !important;
}

.post-main-banner-buttons .wp-block-column {
    width: 42%;
}

.wp-block-columns.post-main-banner-buttons {
    max-width: unset;
    width: 100%;
}

/* Element | http://localhost:3000/relaxing-at-the-cliffs-of-moher */

div.wp-block-columns:nth-child(2) {
    padding: 50px 0;
}

/* Inline #24 | http://localhost:3000/relaxing-at-the-cliffs-of-moher */

.aligncenter.size-full {
    padding: 10px 0z;
}

.post-message-title {
    color: #1b6047;
    font-family: 'Kells';
    padding: 10px 0;
    font-size: 25px;
    font-weight: 600;
    margin-top: 15px;
}

.post-message-text {
    color: #292d32;
    font-size: 18px;
}

.has-text-align-center.post-main-body-title {
    text-align: center;
    color: #0b3144 !important;
}

.post-main-body-title span {
    color: #0b3144 !important;
    font-size: 40px;
    font-weight: bolder;
}

/* Inline #24 | http://localhost:3000/relaxing-at-the-cliffs-of-moher */

.post-inner-mini-title {
    color: #2b3143;
    font-size: 21px !important;
}

/* Inline #24 | http://localhost:3000/relaxing-at-the-cliffs-of-moher */

.post-column-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.post-column-data * {
    width: 100%;
    text-align: left;
}

/* Inline #23 | http://localhost:3000/relaxing-at-the-cliffs-of-moher */

/* Inline #24 | http://localhost:3000/relaxing-at-the-cliffs-of-moher */

.post-inner-title {
    /* font-family: ""; */
    color: #66aa47;
    font-size: 29px;
    font-weight: bolder;
    font-family: 'Poppins';
}

/* Inline #24 | http://localhost:3000/relaxing-at-the-cliffs-of-moher */

.post-column-data p {
    font-size: 16px;
}

#main-subtitle {
    margin-bottom: 0px;
}

.peek-main .main-title {
    margin-top: 0px;
}

@media screen and (max-width: 600px) {
    .inversed {
        display: flex;
        flex-direction: column-reverse !important;
    }

    /* Inline #23 | http://localhost:3000/relaxing-at-the-cliffs-of-moher */

    .wp-block-columns {
        /* flex-direction: row; */
        flex-direction: column;
    }

    .wp-block-column {
        /* width: 50%; */
        width: 100%;
    }

    .wp-block-button.post-join-button {
        margin: 10px 0;
    }

    .post-column-data {
        text-align: center;
    }

    /* Inline #24 | http://localhost:3000/relaxing-at-the-cliffs-of-moher */

    .post-main-banner-image {
        margin: 20px 0;
    }

    .post-column-img img,
    .post-main-banner-image img {
        height: 100%;
        width: 100%;
    }

    .post-main-banner-image img {
        padding: 0 30px;
    }

    .post-column-data * {
        text-align: center;
    }

    .post-main-banner-paragraph,
    .post-message-text,
    .post-column-data {
        padding: 0 30px;
    }

    .has-text-align-center.post-main-banner-subtitle span {
        font-size: 35px;
    }

    .post-main-banner-title {
        font-size: 20px;
    }

    .post-main-banner-buttons .wp-block-column {
        width: 70%;
    }

    .post-message-text {
        font-size: 14px;
    }

    .post-main-body-title span {
        font-size: 30px;
    }

    .post-inner-mini-title {
        padding-bottom: 20px;
    }

    .post-main-banner-paragraph {
        font-size: 14px;
    }

    .post-column-data p {
        font-size: 14px;
    }

    /* Inline #19 | http://localhost:3000/peek-inside */

    .join h2 {
        /* font-size: 38px; */
        font-size: 30px;
    }

    .join p {
        /* font-size: 18px; */
        font-size: 16px;
    }

    /* Inline #22 | http://localhost:3000/peek-inside */

    .claim {
        /* width: 150px; */
        width: 70%;
    }

    .gift {
        /* width: 150px; */
        width: 70%;
    }

    /* Inline #22 | http://localhost:3000/peek-inside */

    .post h1 {
        /* font-size: 18px; */
        font-size: 22px;
    }

    .post p {
        /* font-size: 12px; */
        font-size: 16px;
    }

    /* Inline #22 | http://localhost:3000/peek-inside */

    .main-title {
        /* font-size: 40px; */
        font-size: 30px;
    }

    .main-paragraph {
        /* font-size: 16px; */
        font-size: 14px;
    }

    /* Inline #23 | http://localhost:3000/peek-inside */
    .main-title {
        /* font-size: 30px; */
        font-size: 25px;
    }

    .main-paragraph {
        margin-top: 20px;
    }

    /* Inline #22 | http://localhost:3000/peek-inside */

    .claim {
        justify-content: center;
    }
    .textSwipeMob {
        display: block;
    }
    .claim svg {
        /* padding-left: 5px; */
        padding-left: 0;
    }

    /* Inline #8 | http://localhost:3000/peek-inside */

    .offPragraph {
        /* font-size: 16px; */
        font-size: 14px;
        padding: 10px;
    }
    .desPage {
        display: none;
    }
    .mobPage {
        display: block;
        width: 100%;
        margin: auto;
    }
    .stf__wrapper {
        padding-bottom: 0px !important;
    }
    .page {
        /* width: 100% !important; */
        /* right: -56px !important; */
    }
    .flipbook-container img {
        object-fit: cover;
        /* height: auto; */
    }
    .flipbook {
        min-width: 100% !important;
    }
    /* .mobPage {
        display: block;
        width: 90%;
        margin: auto;
    }
    .page {
        width: 100% !important;
        left: 0px !important;
        height: auto !important;
    }
    .flipbook {
        min-width: 90% !important;
        min-height: auto !important;
    }
    .flipbook-container img {
        object-fit: contain;
        height: auto;
    } */
    /* Inline #7 | http://localhost:3000/peek-inside */
    .swipe-controls {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .flipbook {
        /* margin-bottom: -5px; */
        margin-bottom: 40px;
    }
}
