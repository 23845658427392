.articles {
    display: flex;
    justify-content: space-between;
    padding: 50px 30px;
    margin-top: 50px;
    border-radius: 8px;
    background: rgba(244, 244, 244);
}
.articles a,
.grid-all a {
    text-decoration: none;
    margin: 0;
}
.blog-container {
    width: 1200px;
}
.blog-tag {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding-top: 50px;
    padding-left: 0px;
    margin: 0;
}
.blog-tag li {
    list-style: none;
    margin: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
    color: black;
    font-weight: 500;
    margin-right: 50px;
    width: 12%;
    padding: 5px 10px;
    text-align: center;
}
.tab-active {
    background: #48874c;
    color: white !important;

    border-radius: 70px;
}

.last-blog {
    width: 49%;
    margin: 0;
}
.last-blog img {
    border-radius: 8px;
    width: 100%;
    height: 350px;
    object-fit: cover;
}
.last-blog h2 {
    color: #48874c;
    font-weight: 500;
    font-size: 22px;
}
.category-tag {
    margin: 10px 0px;
    padding: 2px 0px;
    background: #ebebeb;
    width: 100px;
    font-weight: 600;
    text-align: center;
    border-radius: 60px;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1em;
    color: black;
}
.blog-shortdesc p {
    margin: 0px;
    display: inline;
}
.relevant-display {
    display: flex;
    flex-direction: column;
    width: 49%;
    margin: 0;
    /* justify-content: space-between; */
}
.relevant-articles {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    /* flex-direction: column; */
}
.relevant-articles img {
    height: 130px;
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
}
.relevant-description {
    width: 60%;
    padding-left: 20px;
}

.relevant-description h2 {
    color: #48874c;
    font-size: 18px;
}
.grid-all {
    display: grid;
    column-gap: 20px;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 10px;
    padding-top: 50px;
}
.all-articles,
.all-articles img {
    width: 100%;
}
.all-articles img {
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
}
.all-articles h2 {
    color: #48874c;
    font-size: 18px;
}
.travel-articles {
    display: grid;
    column-gap: 20px;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 10px;
    padding-top: 50px;
}
.travel-box img {
    width: 100%;
    border-radius: 8px;
    height: 150px;
    object-fit: cover;
}
.travel-box {
    text-decoration: none;
}
.travel-box h2 {
    color: #48874c;
    font-size: 18px;
}
@media (max-width: 998px) {
    .blog-container {
        width: 100%;
        padding: 0px 20px;
    }
    .grid-all,
    .travel-articles {
        grid-template-columns: repeat(2, 1fr);
    }
    .blog-tag li {
        width: 20%;
        margin: 0;
    }
}

@media (max-width: 767px) {
    .grid-all,
    .travel-articles {
        grid-template-columns: repeat(1, 1fr);
    }
    .articles {
        flex-direction: column;
    }
    .last-blog {
        width: 100%;
    }
    .last-blog img {
        height: unset;
    }
    .blog-tag {
        flex-direction: column;
    }
    .relevant-display,
    .blog-tag li {
        width: 100%;
    }
    .blog-tag li {
        margin: 0;
        margin-bottom: 10px;
    }
}
