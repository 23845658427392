.topblogsection {
    padding-top: 20px;
    /* display: flex; */
    width: 1200px;
    margin: 0 auto;
    /* background: #F4F4F4; */
}
.similarblogs {
    width: 1100px;
    /* margin: 0 auto; */
    padding: 50px 0px;
}
.blogs {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    width: 1100px;
    margin: 0 auto;
    padding: 50px 0px;
}
.singleblog-blogs {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    width: 100%;
    margin: 0 auto;
}
.postImg {
    width: 100%;
    object-fit: cover;
    height: auto;
    min-height: 220px;
    border-radius: 10px;
}
.single-blog {
    width: 100%;
}
.blog-featured-img {
    min-height: 220px;
}
.blog-desc a {
    text-decoration: none;
}
.blog-desc {
    width: 100%;
}
.blog-desc h1 {
    color: #35424f;
    font-size: 30px;
    margin: 10px 0px;
}
.blog-desc .blog-content {
    color: #798fa5;
    font-size: 16px;
}
.blog-category {
    margin: 10px 0px;
    color: #35424f;
    font-size: 12px;
    font-weight: 700;
}
.read-more {
    color: #48874c;
}
.rmicon {
    margin-left: 10px;
}
.single-blog-page {
    padding: 100px 0px;
    width: 1100px;
    margin: 0 auto;
}
.single-blog-page h1 {
    text-align: left;
    color: #48874c;
    padding-bottom: 20px;
    font-size: 24px;
}
.single-blog-page h2 {
    text-align: left;
    color: #48874c;
    padding-bottom: 20px;
    font-size: 18px;
}
.singleblog-header {
    font-size: 48px !important;
}
.singleblog-banner {
    width: 100%;
    height: 500px;
    border-radius: 21.0101px;
    object-fit: cover;
}
.singleblog-display {
    display: flex;
    justify-content: space-between;
}
.single-blog-content {
    width: 65%;
    padding-top: 50px;
    /* margin: 0 auto; */
    text-align: left;
}
.wp-block-image img {
    width: 100%;
    height: 500px;
    object-fit: cover;
    margin: 20px 0px;
}
.similarblogs {
    display: flex;
    flex-direction: column;
    width: 30%;
}
.single-blog-content h1 {
    text-align: left;
}
.blog-shortdesc {
    color: black;
}
.allblogs {
    text-align: center;
    padding-bottom: 50px;
}
.allblogs button {
    border: 1px solid #48874c;
    padding: 10px 20px;
    color: #48874c;
    background-color: transparent;
    border-radius: 5px;
}
.allblogs button:hover {
    border: 1px solid #48874c;
    padding: 10px 20px;
    color: white;
    background-color: #48874c;
    border-radius: 5px;
}
.featured-blog-image img {
    width: 100%;
    object-fit: cover;
}
.similar-blogs {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}
.other-blog {
    width: 200px;
    height: 300px;
}
/* .single-featured-blog img{
    width: 400px;
    height: 400px;
} */
/* .top-blog-posts{
    min-height: 220px;
    width: 100%;
} */
.featuredblogimg {
    width: 250px;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}
.single-featured-blog {
    width: 50%;
    /* display: flex; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
    height: 100%;
}
#portrait-featured-img {
    height: 50%;
    width: 100%;
}
.col-featured-blogs {
    width: 50%;
}
.top-blog-posts {
    display: flex;
    width: 1100px;
    margin: 0 auto;
    /* background: #F4F4F4; */
}

.col-top-featured-blog {
    display: flex;
    padding: 20px 0px;
    height: 50%;
}
.col-top-featured-blog .blog-desc {
    width: 50%;
}
@media (max-width: 1200px) {
    .top-blog-posts {
        display: flex;
        width: 100%;
        margin: 0 auto;
        /* background: #F4F4F4;
        border-bottom: 1px solid black; */
    }
    .single-featured-blog {
        padding-top: 0;
        padding-right: 10px;
        padding: 10px;
        padding-top: 0;
    }
    .col-featured-blogs {
        /* border-left: 1px solid black; */
        padding-left: 10px;
    }
    .col-top-featured-blog {
        padding-top: 0px;
    }
    .wp-block-image img {
        width: 100%;
        height: 100%;
    }
    .singleblog-display {
        flex-direction: column;
    }
}
@media (max-width: 1199px) and (min-width: 768px) {
    .topblogsection {
        width: 100%;
        padding: 0px 20px;
    }
    .blogs {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 30px;
        width: 100%;
        margin: 0 auto;
        padding: 50px 20px;
    }
    .single-blog-page {
        padding: 50px 30px;
        width: 100%;
        margin: 0 auto;
    }

    .top-blog-posts {
        width: 100%;
        flex-direction: column;
    }
    .single-featured-blog {
        width: 100%;
    }
    .col-featured-blogs {
        width: 100%;
    }
    .col-top-featured-blog {
        flex-direction: column;
    }
    .col-top-featured-blog > div {
        width: 100%;
    }
    .col-top-featured-blog {
        padding: 0px 20px;
    }
    .col-top-featured-blog .featuredblogimg {
        width: 100%;
    }
    .col-top-featured-blog .blog-desc {
        width: 100%;
    }
    .single-blog-content {
        width: 100%;
        padding-top: 50px;
        margin: 0 auto;
    }
    .similarblogs {
        width: 100%;
        padding: 20px;
    }
    .singleblog-blogs {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 30px;
        width: 100%;
        margin: 0 auto;

        padding-top: 20px;
    }

    .col-featured-blogs {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (max-width: 767px) {
    .blogs {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 30px;
        width: 100%;
        margin: 0 auto;
        padding: 50px 20px;
    }
    .single-blog-page {
        padding: 50px 20px;
        width: 100%;
        margin: 0 auto;
    }
    .singleblog-banner {
        height: unset;
        margin-bottom: 20px;
    }
    .single-blog-banner {
        height: 200px;
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
    }
    .top-blog-posts {
        width: 100%;
        flex-direction: column;
    }
    .single-featured-blog {
        width: 100%;
    }
    .col-featured-blogs {
        width: 100%;
    }
    .col-top-featured-blog {
        flex-direction: column;
    }
    .col-top-featured-blog {
        padding: 0px 20px;
    }
    .col-top-featured-blog .featuredblogimg {
        width: 100%;
    }
    .featuredblogimg img {
        width: 100% !important;
        object-fit: cover !important;
        height: auto;
        min-height: 220px;
    }
    #portrait-featured-img {
        width: 100% !important;
        object-fit: cover !important;
        height: 220px !important ;
        min-height: 220px;
    }
    .col-top-featured-blog .blog-desc {
        width: 100%;
    }
    .single-blog-content {
        width: 100%;
        padding-top: 0px;
        margin: 0 auto;
    }
    .similarblogs {
        width: 100%;
        /* padding: 20px; */
    }
    .singleblog-header {
        font-size: 32px !important;
    }
    .singleblog-blogs {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 30px;
        width: 100%;
        margin: 0 auto;

        padding-top: 20px;
    }
}
