.freeButlers {
  width: 100%;
  height: 40px;
  background-color: #48874c;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space;
  position: relative;
}

.freeButlers p {
  font-size: 16px;
  color: #ffffff;
  font-weight: 500;
  margin: 0 auto;
}

.free-buttlers-link {
  text-decoration: none;
  color: #ffffff;
  display: none;
  background-color: #eb5b24;
  text-align: center;
  padding: 10px 40px;
  border-radius: 7px;
}

#orange {
  display: none;
  position: absolute;
  right: 0;
  width: 6px;
  height: 100%;
  background-color: #eb5b24;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

@media screen and (max-width: 768px) {
  .freeButlers {
    height: 65px;
    justify-content: space-between;
    font-size: 12px;
    top: 0;
    padding: 35px 10px;
  }

  .freeButlers p {
    margin: 0 0 0 10px;
    width: 100%;
    font-size: 12px;
  }

  .free-buttlers-link {
    display: block;
    /* width: 150px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0px 0 0;
    font-size: 12px;
    text-align: center;

  }

  #orange {
    display: block;
  }
}