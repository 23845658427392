.founder{
    width: 100%;
    padding: 40px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.fonder-data{
    width: 485px;
    height: 485px;
    background-color: #66AA4721;
    margin: 0;
    border-radius: 40px;
}
.founder-image{
    background-size: cover;
    background-repeat: no-repeat;
}
.founder-text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding:0 20px ;
}
.founder-text h1{
    color: #0B3144;
}
.founder-text h2{
    color: black;
    font-size: 20px;
    font-weight: 400;
    padding: 15px 0;
}
.founder-text p{
    font-family: 'Playfair Display', serif;
    color: #1B572C;
    font-size: 25px;
}

@media screen and (max-width:998px) {
    .founder {
        flex-direction: column;
    }
    .founder-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
        text-align: center;
    }
    .founder-text p{
    font-family: 'Playfair Display', sans-serif ;


    }
    
}

@media screen and (max-width:600px) {
    .founder-text h1 {
        font-size: 25px;
    }
    .founder-text p {
        font-size: 20px;
    }
    .fonder-data{
        width: 95%;
        /* height:350px ; */
    }
    .founder-text p{
        font-family: 'Playfair Display', serif;
    
    
        }
}