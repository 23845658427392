.main-footer {
    background-color: white;
}

.main-footer a:hover {
    color: unset;
}

.footer-container {
    color: black;
    background-color: white;
    padding-top: 3em;
    position: relative;
    bottom: 0;
    max-width: 1500px;
    width: 90%;
}

.list-unstyled {
    list-style: none;
}

.footer-bottom {
    display: flex;
    list-style: none;
    padding: 10px;
    width: 100%;
    font-size: 13px;
}

.rights {
    width: 30%;
    margin-left: 0px;
}

.row {
    display: flex;
    list-style: none;
    padding-bottom: 50px;
}

.hr {
    color: #66aa4785;
}

.col h4 {
    font-size: 14px;
    font-weight: 700;
}

.list-unstyled li {
    font-size: 14px;
    margin: 10px 0px;
}

#col1 {
    padding-right: 10%;
    width: 30%;
}

#col2 {
    width: 15%;
}

#col3 {
    width: 15%;
}

#col4 {
    width: 30%;
}

.footer-submit-button {
    background-color: transparent;
    border: none;
}

.socials {
    display: flex;
    list-style: none;
    padding-top: 50px;
    padding-left: 0px;
}

.socials li {
    margin: 10px;
    margin-left: 0px;
}

.email-signup {
    font-size: 12px;

    text-align: left;
    padding: 0px;
    padding-bottom: 20px;
}

.email {
    background-color: #66aa4754;
    color: #1b6047;
    padding: 10px;
    border: 1px solid #66aa47;
    border-radius: 5px;
    font-size: 16px;
}

.irish-paragraph {
    padding-top: 50px;
    font-size: 16px;
    text-align: left;
}

.email-signup {
    font-size: 14px;
}

#col4 > ui:nth-child(2) > div:nth-child(1) {
    display: flex;
    padding: 0px;
    margin: 0px;
    background-color: #66aa4754;
    color: #1b6047;
    padding: 10px;
    border: 1px solid #66aa47;
    border-radius: 5px;
    width: 280px;
}

#email-subscribe {
    width: 100% !important;
}

.footer-subscribe.subscribe-subscribe {
    width: 100% !important;
}

.email {
    color: #1b6047;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    margin: 0px;
    width: 200px;
}

#col4 > ui:nth-child(2) > div:nth-child(1) > img:nth-child(2) {
    width: 20px;
    height: 18px;
    margin-top: 5%;
    right: 0px;
}

.footer-subscribe {
    display: flex;
    border: 1px solid #66aa47;
    border-radius: 10px;
    background: #66aa4754;
    padding: 10px;
    width: 70%;
    margin: 0;
    align-items: center;
}

#email-subscribe {
    width: 100% !important;
}

.footer-submit-button img {
    width: 20px;
}

.footer-form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.email {
    background-color: transparent !important;
    width: 80% !important;
}

.footer-subscribe > img:nth-child(2) {
    width: 25px !important;
    height: 22px;
    justify-content: center;
    display: flex;
    margin: 12px;
}

li a {
    text-decoration: none;
    color: black;
}

.footer-logo {
    width: 200px;
}

@media screen and (max-width: 998px) {
    .footer-subscribe {
        margin: 0 auto;
    }
}

@media (max-width: 600px) {
    .footer-subscribe {
        margin: 0 auto;
    }

    .main-footer {
        margin-bottom: 50px;
    }

    .irish-paragraph {
        padding-top: 0px;
    }

    .rights {
        width: 100%;
        margin-left: 0px;
        text-align: center;
        list-style: none;
        padding-bottom: 10px;
        font-size: 16px;
        margin: 0;
    }

    .row {
        display: flex;
        list-style: none;
        padding-bottom: 15px;
    }

    .footer-bottom {
        display: flex;
        list-style: none;
        padding: 10px 0px;
        width: 100%;
        font-size: 13px;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .footer-bottom > li:nth-child(2) {
        width: 33%;
        padding-bottom: 10px;
    }

    .footer-bottom > li:nth-child(3) {
        width: 29%;
        padding-bottom: 10px;
    }

    .footer-bottom > li:nth-child(4) {
        width: 27%;
        padding-bottom: 10px;
    }

    .footer-bottom > li:nth-child(5) {
        width: 50%;
        text-align: center;
        padding-bottom: 10px;
    }
    .socials {
        padding-top: 40px;
    }
    .footer-bottom > li:nth-child(6) {
        width: 50%;
        padding-bottom: 10px;
    }

    .row1 {
        width: 100%;
    }

    .email {
        padding: 15px;
    }

    #col4 > ui:nth-child(2) > div:nth-child(1) {
        display: flex;
        flex-direction: row;
        width: 100%;
        max-width: 100%;
        background-color: #66aa4754;
        color: #1b6047;
        padding: 10px;
        border: 1px solid #66aa47;
        border-radius: 5px;
    }

    .email {
        background-color: transparent;
        width: 100%;
        background-color: transparent;
        color: #1b6047;
        padding: 10px;
        border: none;
        border-radius: 5px;
        padding: 0px;
    }

    #col4 > ui:nth-child(2) > div:nth-child(1) > img:nth-child(2) {
        width: 20px;
        height: 20px;
        justify-content: center;
        margin-top: 2%;
        margin-right: 5%;
    }

    .footer-subscribe {
        display: flex;
        border: 1px solid #66aa47;
        border-radius: 10px;
        background: #66aa4754;
        padding: 10px;
        width: 100%;
    }

    .footer-subscribe img {
        width: 25px !important;
        height: 20px;
        justify-content: center;
        display: flex;
        margin: 10px;
    }
}
