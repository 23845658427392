/* Reviews.module.css */

.popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.popupContent {
    margin: 100px auto;
    padding: 20px;
    background-color: #fff;
    width: 60%;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 10px;
    font-size: 14px;
}

.formLabel {
    display: block;
    margin-bottom: 10px;
}

.inputField {
    margin-left: 10px;
}

.textareaField {
    width: 100%;
    min-height: 100px;
}

.button {
    padding: 10px 20px;
    font-size: 16px;
    margin-right: 10px;
}
/* Reviews.module.css */

.acceptedMessage {
    margin-top: 20px;
    padding: 20px;
    background-color: #e6ffe6;
    border: 1px solid #99ff99;
    border-radius: 4px;
}

.submittedDetails {
    list-style-type: none;
    padding-left: 0;
}

.submittedDetails li {
    margin-bottom: 10px;
}
.singleMessageContainer {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.backButton {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
}

.messageContent {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
}

.submittedDetails {
    list-style-type: none;
    padding: 0;
}
