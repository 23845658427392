.sixth-section {
  padding-top: 100px;
}

.sixthBanner {
  border: 2px solid green;
  width: 80%;
  max-width: 1500px;
  display: flex;
  flex-direction: row;
  background-color: #f0efdc;
  border-radius: 20px;
  height: fit-content;
}

.sixthButton {
  padding: 15px;
  background-color: #48874c;
  text-decoration: none;
  text-align: center;
  width: 40%;
  margin-left: 30%;
  color: white;
  font-size: 18px;
  outline: none;
  border-radius: 10px;
  border: none;
}

.sixthButton:hover {
  color: white;
}

.left-side {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0;
}

.right-side {
  margin: 0;
  padding: 0px !important;
  padding: 0;
  display: flex;
  margin: 0;
  width: 450px;
}

.left-side h2 {
  text-align: center;
  padding: 0px 0px 20px 0;
  color: green;
  font-size: 15px;
}

.left-side p {
  text-align: center;
  padding: 20px 0px;
}

.right-side img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  border-left: 2px solid green;
}

.katharine-heading {
  color: black;
  font-size: 35px;
  height: auto;
  font-family: "Kells";
}

/*tablet styling */
@media screen and (max-width: 998px) {
  .sixth-section {
    padding: 30px;
  }

  .sixthBanner {
    height: fit-content;
    flex-direction: column-reverse;
    width: 100%;
  }

  .right-side {
    margin: 0;
    padding: 0px !important;
    height: 100%;
    padding: 0;
    display: flex;
    margin: 0;
    width: 100%;
  }

  .left-side {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 20px;
  }

  .sixthButton {
    padding: 15px;
    background-color: #48874c;
    text-align: center;
    width: 250px;

    color: white;
    font-size: 18px;
    outline: none;
    border-radius: 10px;
    border: none;
    margin: 29px auto;
  }

  .left-side h2 {
    text-align: center;
    padding: 20px 0px 20px 0;
    color: green;
    font-size: 15px;
  }

  .left-side p {
    line-height: 30px;
  }
}

/*mobile styling*/
@media (max-width: 600px) {
  .sixth-section {
    padding: 20px;
  }

  .left-side p {
    padding: 0px;
  }
}