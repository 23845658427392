#navbar {
    max-width: 1500px;
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    top: 40px;
}

.allLinks {
    margin-right: 50px;
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#dropdown-basic:focus,
#dropdown-basic,
#dropdown-basic:active,
#dropdown-basic:hover {
    outline: none !important;
    background-color: transparent !important;
    color: #0b3144;
    border: none !important;
    font-size: 18px;
    font-weight: 700;
}

.orange-label {
    position: absolute;
    right: 0;
    width: 10px !important;
    height: 10px !important;
    border-radius: 50%;
    background-color: #eb5b24;
}

.mobileNavbar {
    display: none;
}

.burger-icon {
    display: none;
}

#navbar-btn {
    width: 150px;
    height: 40px;
    background-color: #eb5b24;
    border-radius: 7px;
    color: white;
    border: 0;
    font-size: 18px;
    margin-left: 20px;
    font-weight: 500;
}

.subscribes {
    border-radius: 7px;
}

.mobile-navbar-btn {
    width: 170px;
    height: 55px;
    background-color: #eb5b24;
    border-radius: 7px;
    color: white;
    border: 0;
    font-size: 18px;
    font-weight: 500;
    margin-top: 20px;
}

.hoverable:hover {
    transition: all 0.2s ease-in;
    transform: scale(1.05);
    cursor: pointer;
    /* color: white; */
}

.mobile-navbar a:hover,
.mobile-navbar p:hover,
.allLinks:hover,
.allLinks a:hover {
    color: #0b3144 !important;
}

.hoverable {
    transition: all 0.2s ease-in;
}

.logo {
    margin-left: 0;
    display: flex;
    align-items: center;
}
.navbar-shirit {
    background-color: #056f07;
    color: white;

    height: 70px;
    clip-path: polygon(4% 0, 100% 0, 96% 100%, 100% 100%, 0 100%);
    display: flex;
    align-items: center;
}
.navbar-shirit img {
    width: 90px !important;
    height: 30px !important;
    object-fit: contain;
}
.navbar-shirit p {
    padding: 0px 20px;
    margin: 0px;
}
.logo a {
    margin: 0;
}

.links {
    text-decoration: none;
    color: #0b3144;
    font-size: 18px;
    font-weight: 700;
    margin: 0 20px;
}

.burger-icon-button {
    display: none;
}

.mobile-navbar p {
    display: none;
}

.mobile-navbar a {
    display: none;
}

#banner {
    background-size: cover;
}

.logo img {
    width: 140px;
}

.navbar-dropwdown {
    width: 90%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    align-items: flex-end;
    cursor: pointer;
    position: relative;
}

.navbar-dropwdown svg {
    margin: 0;
}

.navbar-dropdown-paragraph {
    border-bottom: none !important;
    width: fit-content !important;
}

.dropdown-links {
    width: 100%;
    transform: translateY(0rem) translateZ(0px);
}

.dropdown-links a {
    font-size: 16px;
    color: #0b3144;
    margin: 5px 0;
}

.navbar-dropwdown-relative {
    width: 90%;
    position: relative;
}

.navbar-dropwdown {
    width: 90%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    align-items: flex-end;
    cursor: pointer;
    position: relative;
}

.navbar-dropwdown svg {
    margin: 0;
}

.navbar-dropdown-paragraph {
    border-bottom: none !important;
    width: fit-content !important;
}

.dropdown-links {
    width: 100%;
    transform: translateY(0rem) translateZ(0px);
    /* background-color: white; */
}

.dropdown-links a {
    font-size: 16px;
    color: #0b3144;
    margin: 5px 0;
}

.navbar-dropwdown-relative {
    width: 90%;
    position: relative;
}
.shirit-mobile {
    display: none;
}

.swiper-wrapper {
    -webkit-transition-timing-function: linear !important;
    -o-transition-timing-function: linear !important;
    display: flex;
    align-items: center;
    width: 100%;

    transition-timing-function: linear !important;
}
.swiperWrapperTwo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    align-items: center;
    margin: a;
    /* height: 40px; */
    /* padding: 10px 0; */

    background-color: #eb5b24;
}
.swiper-wrapper .swiper-slide {
    transition-property: -webkit-transform;
    transition-property: transform;
}
@media (min-width: 999px) {
    .ensureChristmas-navbar {
        background: #48874c;
        justify-content: center !important;
        text-align: center !important;
        align-items: center;
        padding: 0 !important;
        margin: 0 !important;
    }

    .counter {
        width: 300px !important;
        padding-left: 20px;
    }

    .ensure {
        font-size: 16px;
        color: white !important;
        padding-bottom: 0;
        width: auto !important;
        margin: 0 !important;
    }

    .days,
    .hours,
    .minutes,
    .sec {
        width: 60px !important;
        height: 60px !important;
    }

    .counter h1 {
        font-size: 18px !important;
    }

    .counter p {
        font-size: 10px !important;
    }
    .ensureChristmas-navbar {
        background: #48874c;
        justify-content: center !important;
        text-align: center !important;
        align-items: center;
        padding: 0 !important;
        margin: 0 !important;
    }

    .counter {
        width: 300px !important;
        padding-left: 20px;
    }

    .ensure {
        font-size: 16px;
        color: white !important;
        padding-bottom: 0;
        width: auto !important;
        margin: 0 !important;
    }

    .days,
    .hours,
    .minutes,
    .sec {
        width: 50px !important;
        height: 50px !important;
    }

    .counter h1 {
        font-size: 18px !important;
    }

    .counter p {
        font-size: 10px !important;
    }
    .ensureChristmas-navbar {
        background: #48874c;
        justify-content: center !important;
        text-align: center !important;
        align-items: center;
        padding: 0 !important;
        margin: 0 !important;
    }

    .counter {
        width: 300px !important;
        padding-left: 20px;
    }

    .ensure {
        font-size: 16px;
        color: white !important;
        padding-bottom: 0;
        width: auto !important;
        margin: 0 !important;
    }

    .days,
    .hours,
    .minutes,
    .sec {
        width: 60px !important;
        height: 60px !important;
    }

    .counter p {
        font-size: 10px !important;
    }
}

@media screen and (max-width: 998px) {
    .ensureChristmas-navbar {
        /* display: flex !important; */
        flex-direction: column !important;
    }

    .ensure {
        color: white;
        margin: 0;
        margin-top: 10px !important;
    }
    .ensureChristmas-navbar {
        /* display: flex !important; */
        flex-direction: column !important;
    }

    .ensure {
        color: white;
        margin: 0;
        margin-top: 10px !important;
    }
    .ensureChristmas-navbar {
        /* display: flex !important; */
        flex-direction: column !important;
    }

    .ensure {
        color: white;
        margin: 0;
        margin-top: 10px !important;
    }
    .logo img {
        margin-left: 10px;
        width: 140px !important;
    }

    .dropdown-menu.show {
        width: 100%;
        transition: all 0.9s ease-out;
        color: #0b3144 !important;
    }

    .mobile-navbar a {
        color: #0b3144;
        text-decoration: none;
    }

    .links {
        display: none;
    }

    .mobile-navbar {
        margin-top: 65px !important;
    }
    .swiperWrapperTwo {
        display: flex !important;
    }
    .burger-icon {
        display: block;
        border: none;
        background-color: transparent;
        margin: 0 10px 0 0;
    }

    .navbar {
        justify-content: space-between;
        margin: 0;
    }

    .logo {
        margin: 0;
    }

    .allLinks {
        margin: 0;
    }

    .burger-icon-button {
        margin: 0 0 0 10px;
        display: block;
    }

    .allLinks {
        display: none;
    }

    .mobile-navbar {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: #fffcf8;
        z-index: 111;
        left: 0;
        top: 0;
        margin-top: 139px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        transition: ease-in-out 0.2;
    }

    .react-reveal.mobile-navbar {
        z-index: 9999999999;
    }

    .mobile-navbar p {
        margin: 0;
        font-size: 18px;
        color: #0b3144;
        font-weight: 500;
        margin-top: 30px;
        width: 90%;
        text-align: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        display: block;
    }

    .mobile-navbar a {
        display: block;
    }

    .fixed {
        position: fixed !important;
        z-index: 1111 !important;
        top: 0 !important;
    }

    .subscribes {
        display: none;
    }

    .logo img {
        margin-left: 10px;
        width: 132px;
    }

    .mobile-navbar a {
        display: block;
        text-align: left !important;
    }

    #fixed {
        position: fixed !important;
        z-index: 99999999999;
    }

    #navbar {
        height: 65px !important;
        z-index: 99999999999999 !important;
    }

    #dropdown-basic:focus,
    #dropdown-basic,
    #dropdown-basic:active,
    #dropdown-basic:hover {
        outline: none !important;
        background-color: transparent !important;
        color: #0b3144;
        border: none !important;
        font-size: 18px;
    }

    .dropdown {
        margin: 0 !important;
        text-align: left;
    }

    .orange-label {
        left: -6px;
        right: unset;
        top: 25px;
    }
}
@media screen and (max-width: 768px) {
    .shirit-desktop {
        display: none;
    }
    .shirit-mobile {
        display: none;
        clip-path: unset;
    }
    .shirit-mobile p {
        padding: 5px 10px;
        font-size: 12px;
    }
    .handbag-title {
        padding-top: 0px !important;
    }
    .navbar-shirit {
        height: 50px;
    }
}
