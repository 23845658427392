.second-section {
    width: 100%;
    height: auto;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-top: 50px;
}

.sbTitle {
    font-family: 'The new Elegance';
    /* font-style: italic; */
    font-weight: bold;
    font-size: 27px;
    letter-spacing: 1px;
    color: #0b3144;
}

.how-it-works {
    width: 1200px;
    height: 320px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.hw-step {
    width: 318px;
    height: 325px;
    margin: 0;

    display: flex;
    flex-direction: column;
    text-align: center;
    border-radius: 7px;
    align-items: center;
}

.step-animation {
    width: 300px;
    height: 130px;
    margin-bottom: 65px;
}

.step-heading {
    font-size: 27px;
    color: #1b6047;
}

.step-description {
    font-size: 16px;
    color: #000000;
    width: 80%;
    text-align: center;
    align-items: center;
    margin-top: 10px;
    padding-top: 10px;
}

.subscribe {
    padding: 15px 25px;

    color: #ffffff;
    border: none;
    border-radius: 7px;
    font-size: 19px;
    font-weight: 500;
    text-decoration: none;
    margin-bottom: 50px;
    margin-top: 190px;
    background: linear-gradient(to right, white 50%, #86a141 50%);
    background-size: 200% 100%;
    border: 1px solid #86a141;
    background-position: right bottom;
    transition: all 0.5s ease-out;
}

.subscribe:hover {
    background-position: left bottom;
    color: #86a141;
}

.subscribe a {
    text-decoration: none;
}

.hw-step h1 {
    margin: 65px 0 0 0;
}

.hw-step p {
    margin: 0;
}

.steps {
    margin-bottom: -40px !important;
    margin-top: 40px !important;
    color: #ea5b24;
    font-weight: 500;
}

/*TABLET styling */
@media screen and (max-width: 998px) {
    .second-section {
        padding: 10px 20px;
    }

    .sbTitle {
        text-align: center;
        padding-top: 20px;
    }

    .hw-step p {
        margin: 0 auto;
        text-align: center;
        align-content: center;
        width: 283px;
    }

    .subscribe {
        margin: 20px auto;
    }

    .how-it-works {
        width: 100%;
        height: -moz-fit-content;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        padding: 30px 30px;
    }

    .steps {
        margin-bottom: -39px;
        margin-top: 30px;
        color: #ea5b24;
        font-weight: 500;
    }

    .hw-step {
        width: 318px;
        height: auto;
        flex-direction: column;
        text-align: center;
        border-radius: 7px;
        margin-top: 39px;
    }
}

/*MOBILE styling */
@media screen and (max-width: 767px) {
    .step-animation {
        width: 270px;
        height: 130px;
        margin-bottom: 65px;
    }

    .sbTitle {
        font-size: 30px;
        padding-bottom: 20px !important;
    }

    .how-it-works {
        padding-top: 0px;
        margin-top: -21px;
    }

    .hw-step {
        flex-direction: column;
        margin-top: 20px;
    }

    .hw-step p {
        margin: 0 auto;
    }
}
